<template>
  <div id="typing">
    <div class="message-inner w-auto">
      <div class="typing">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    from: {
      type: String,
    },
    agent: {
      type: Object,
      default: () => ({}),
    },
    botName: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
// three dots chat typing effect
.typing {
  align-items: center;
  display: flex;
  height: 17px;
  background-color: #fafafa;
  border: 1px solid #cdcdcd;
  width: fit-content;
  padding: 1.5rem;
  margin-left: 3.45rem;
  border-radius: 1rem;

  .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: rgba(2, 26, 16, 0.7) !important;
    border: 1px solid rgba(2, 26, 16, 0.7) !important;
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;

    &::nth-child(1) {
      animation-delay: 200ms;
    }
    &:nth-child(2) {
      animation-delay: 300ms;
    }
    &:nth-child(3) {
      animation-delay: 400ms;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #ffffff; // rgba(20,105,69,.7);
  }
  28% {
    transform: translateY(-7px);
    background-color: #ffffff; //rgba(20,105,69,.4);
  }
  44% {
    transform: translateY(0px);
    background-color: #ffffff; //rgba(20,105,69,.2);
  }
}

@keyframes slide {
  100% {
    width: 150px;
    right: 50px;
  }
}
</style>