<template>
  <div v-if="message && message.payload">
    <p v-if="message.payload?.approximate_adress" class="text_sm mb-3"><b>Dirección aproximada:</b> {{ message.payload.approximate_adress }}</p>
    <iframe
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      class="db-location"
      :src="`https://maps.google.com/maps?q=${message.payload.latitude},${message.payload.longitude}&hl=es&z=14&amp;output=embed`"
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.db-location {
  width: 800px;
  height: 470px;
}
</style>
