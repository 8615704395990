<template>
  <div class="databot_carousel">
    <div class="slider_box">
      <div
        class="mySlides"
        v-show="currentSlide === itemIndex"
        v-for="(item, itemIndex) in items"
        :key="itemIndex"
      >
        <div
          :class="{
            card: true,
            'card-xl': carouselType === 'recommender_xl',
            'text-center': (!ecommerces.includes(origin) || isConstructor31Bot) && type !== 'option'
          }"
        >
          <div class="card_box">
            <p
              v-if="type === 'option'"
              :class="{
                'carrusel-title': true,
                ' carrusel-title-xl': carouselType === 'recommender_xl'
              }"
            >
              {{ item.title }}
            </p>
            <div v-else :class="carouselType === 'recommender_xl' ? 'between_box mt-0' : 'text-center mt-0'">
              <p
                :class="{
                  'carrusel-title': true,
                  ' carrusel-title-xl': carouselType === 'recommender_xl'
                }"
              >
                {{ item.title }}
              </p>
              <span v-if="carouselType === 'recommender_xl'" class="text-recomend recomendado-label"
                >{{ item.productCartRecommend }}% Recomendado</span
              >
            </div>

            <!-- image to show to carousel option -->
            <a
              v-if="type === 'option'"
              :href="
                item.buttons[0].value.includes('http') ? item.buttons[0].value : item.image_url
              "
              target="_blank"
            >
              <img
                :class="[
                  carouselType === 'recommender_xl'
                    ? 'img-item_xl'
                    : item.filterHeightActive
                    ? 'img-item_mod'
                    : 'img-item'
                ]"
                :src="item.image_url || bot.bot_logo_url"
                :alt="item.title"
                id="carousel_img"
              />
            </a>
            <!-- image to show to carousel recommender -->
            <div v-else class="p-relative">
              <span
                v-if="item.normalPrice && item.price != item.normalPrice"
                :class="{
                  'text-label': true,
                  'text-label-sm': true,
                  'text-label-fill': true,
                  'oferta-label': true,
                  'original-position-offer': carouselType !== 'recommender_xl'
                }"
                >¡Oferta!</span
              >
              <div v-if="item.generalRating >= 0 && carouselType === 'recommender_xl'" class="stars_box">
                <span class="text-rating">{{
                  item.generalRating > 0 ? item.generalRating.toFixed(1) : 0
                }}</span>
                <img class="stars" src="/img/icons/rating/stars/0.2-star.svg" alt="Star" />
              </div>
              <a
                :href="
                  item.buttons[0].value.includes('http') ? item.buttons[0].value : item.image_url
                "
                target="_blank"
              >
                <img
                  :class="[
                    carouselType === 'recommender_xl'
                      ? 'img-item_xl'
                      : item.filterHeightActive
                      ? 'img-item_mod'
                      : 'img-item'
                  ]"
                  :src="item.image_url || bot.bot_logo_url"
                  :alt="item.title"
                  id="carousel_img"
                />
              </a>
            </div>
            <p v-if="item.description" class="carrusel-description txt-ellipsis-3">
              {{ item.description }}
            </p>

            <div
              v-if="item.price && carouselType === 'recommender_xl'"
              class="row justify-content-between py-2 my-3"
            >
              <div class="box-left">
                <label class="d-block"
                  ><b>{{ item.price }}</b></label>
                <span v-if="item.price != item.normalPrice" class="text-muted no-text"
                  >Antes {{ item.normalPrice }}</span
                >
              </div>
              <div class="box-right">
                <div class="input_number-box m-0">
                  <button class="btn-left">-</button>
                  <input
                    type="number"
                    placeholder="0"
                    id="inputNumber1"
                    :value="item.input_value_count"
                    disabled
                  />
                  <button class="btn-right">+</button>
                </div>
              </div>
            </div>
            <div v-else>
              <label class="my-3"
                ><b>{{ item.price }}</b></label
              >
            </div>
            <div v-if="showQuantity" class="input_number-box">
              <button class="btn-left">-</button>
              <input
                type="number"
                placeholder="0"
                id="inputNumber1"
                :value="item.input_value_count"
                disabled
              />
              <button class="btn-right">+</button>
            </div>
          </div>
          <template v-if="carouselType === 'option' || carouselType === 'recommender'">
            <button
              v-for="(button, buttonCarouselIndex) in item.buttons"
              :class="item.buttons.length > 1 ? 'btn-carrusel_2' : 'btn-carrusel'"
              :key="buttonCarouselIndex"
            >
              {{ button.label }}
            </button>
          </template>
          <template v-else-if="item.productOptions.length == 0 || item.productVariants.length == 0">
            <button class="btn-carrusel_2">Agregar al carro</button>
            <span for="item_input" class="btn-text m-auto pb-3">Ver detalle</span>
          </template>
          <!-- BOTON PARA PRODUCTOS CON VARIACIONES -->
          <template v-else-if="carouselType === 'recommender_xl'">
            <button for="item_input" class="btn-carrusel_2 mb-3">
              Agregar al carro</button
            ><input type="hidden" :value="item.id" />
            <span for="item_input" class="btn-text m-auto pb-3">Ver detalle</span>
          </template>
          <input type="hidden" value="2587" id="item_input_1" />
        </div>
      </div>
      <div class="arrow_box">
        <span
          id="prev1"
          class="prev"
          @click="currentSlide === items.length - 1 ? (currentSlide = 0) : (currentSlide += 1)"
          >❮</span
        >
        <span
          id="next1"
          class="next"
          @click="currentSlide === items.length - 1 ? (currentSlide = 0) : (currentSlide += 1)"
          >❯</span
        >
      </div>
    </div>
    <div class="dot_box">
      <span
        v-for="(bullet, bulletIndex) in items"
        :key="bulletIndex"
        :id="`${bulletIndex}`"
        :class="{ bullets: true, active: currentSlide === bulletIndex }"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object
    },
    isConstructor31Bot: {
      type: Boolean
    },
    platform: {
      type: String
    },
    botId: {
      type: Number
    },
    bot: {
      type: Object
    },
    type: {
      type: String
    },
    totalProductRecomended: {
      type: Number
    },
    recommendConversation: {
      type: Number
    },
    productCartRecommend: {
      type: Number
    }
  },
  data() {
    return {
      currentSlide: 0,
      items: [],
      selectedItem: null,
      selectedIndex: 0,
      isShowingRecomendadorXl: false,
      add_to_cart: false,
      origin: "",
      showQuantity: false,
      products: [],
      ecommerces: [
        "vtex",
        "woocommerce",
        "shopify"
        // "bsale",
        // "jumpseller",
      ]
    };
  },
  computed: {
    carouselType() {
      return (
        this.type ||
        (this.ecommerces.includes(this.origin) && !this.isConstructor31Bot
          ? "recommender_xl"
          : "recommender")
      );
    }
  },
  async mounted() {
    // adding custom fields
    this.items = this.message.payload.carousel.elements.map(el => ({
      ...el,
      productCartRecommend: Math.trunc(Math.random() * (99 - 86) + 86),
      input_value_count: 1
    }));
    // get img size to improve images
    this.items = await this.getImgSizeCarousel(this.items);
    // adddind total product counter
  },
  methods: {
    /**
     * @description Metodo para generar menu carrusel. Recibe un payload con formato carrusel
     */
    async getImgSizeCarousel(elements) {
      // set filterHeightActive field to each element using promise all
      const promises = elements.map(item => {
        if (item.image_url) {
          return this.onloadImage(item.image_url);
        }
        return 1;
      });
      const loadedImages = await Promise.all(promises);
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].image_url) {
          let limitWidth = loadedImages[i].naturalWidth * 0.7;

          if (loadedImages[i].naturalHeight < limitWidth) {
            elements[i]["filterHeightActive"] = true;
          } else {
            elements[i]["filterHeightActive"] = false;
          }
        }
      }
      return elements;
    },
    onloadImage(itemUrl) {
      return new Promise((resolve, reject) => {
        let imgSize = new Image();

        imgSize.onload = () => resolve(imgSize);
        imgSize.onerror = err => reject(err);

        imgSize.src = itemUrl;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.databot_carousel {
  animation-name: fade;
  animation-duration: 0.25s;
  padding: 30px 0 10px 0;
}
.slider_box {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;

  .mySlides {
    display: block;

    .card {
      width: 225px;
      border-radius: 1rem;
      border: 1px solid #e8e8e8;
      background-color: #fff;
      transition: 0.5s;
      padding: 0;

      &_box {
        padding: 1.5rem 1.5rem 0;

        img {
          display: flex;
          object-fit: cover;
          object-position: center;
          justify-content: center;
          border: 1px solid #e8e8e8;
          background-color: #fff;
          border-radius: 0.5rem;
          margin: 0 auto;
          width: 100%;
          height: 150px;
        }
        .img-item {
          width: 160px;
        }
        .img-item_xl {
          object-fit: contain;
          object-position: center;
        }
        .img-item_mod {
          width: 100% !important;
          height: auto;
        }
      }
      .carrusel-title {
        color: #181818;
        font-size: 14px;
        font-weight: 700;
        max-height: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .carrusel-title-xl {
        text-align: left;
      }
      .recomendado-label {
        background-color: #8ae04d;
        color: #ffff;
        border: none;
        margin: -33px;
      }
      .btn-carrusel {
        color: #fff;
        width: 100%;
        border: none;
        padding: 1rem 0.75rem;
        font-weight: 700;
        border-radius: 0 0 1rem 1rem;
        background-color: #181818;
        border: 1px solid;
        border-color: #181818;
      }
      .btn-carrusel_2 {
        width: calc(100% - 2.5rem) !important;
        font-weight: 700;
        justify-content: center;
        display: flex;
        margin: 0 auto 1rem;
        padding: 0.75rem;
        border-radius: 5rem !important;
      }
      .btn-carrusel_text {
        cursor: pointer;
        background-color: transparent;
        border: none;
        appearance: none;
        display: block;
        font-size: 14px;
        color: #181818;
        font-weight: 400;
        transition: 0.5s;
        margin: 0.25rem auto 1.25rem;

        &:hover {
          text-decoration: underline;
          transition: 0.5s;
          opacity: 0.7s;
        }
      }
    }

    .card-xl {
      width: 310px;

      .oferta-label {
        position: absolute;
        background-color: #181818;
        top: 1rem;
        left: 1rem;
      }

      .stars_box {
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 5;

        .stars {
          width: 0.75rem;
          height: auto !important;
          border: none;
          margin: auto 0.1rem;
        }
        .text-rating {
          font-size: 14px;
        }
      }
    }

    .btn-text-second {
      display: block;
      text-align: center;
      margin: 1rem auto;
    }
  }
}

.dot_box {
  width: 100%;
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}

/* Next & previous buttons */
.arrow_box {
  position: absolute;
  top: 45%;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 5rem;
    background-color: #b2b2b2;
    transition: 0.6s ease;
    opacity: 0.5;
    // user-select: none;

    &:hover {
      background-color: #181818;
    }
  }

  /* Position the "next button" to the right */
  .prev {
    left: 1rem;
    padding-right: 0.1rem;
  }

  .next {
    right: 1rem;
    padding-left: 0.1rem;
  }
}

/* The dots/bullets/indicators */
.bullets {
  cursor: pointer;
  width: 0.75rem;
  height: 0.75rem;
  margin: 0 0.3rem;
  background-color: #e8e8e8;
  border-radius: 50%;
  border: 1px solid #cdcdcd;
  display: inline-block;
  transition: background-color 0.6s ease;

  &:hover {
    background-color: #181818;
  }
}

.active {
  background-color: #181818;
}

.recomendador-xl {
  position: absolute;
  top: 0;
  right: 0;
  width: 410px;
  height: 100%;
  max-height: 100%;
  background-color: #fff;

  .variant_options {
    flex-wrap: wrap;
    gap: 5px;
  }

  @media (max-width: 480px) {
    width: 100%;
  }

  .recomendador_box {
    margin-top: 2.75rem;
    padding: 2.75rem 2.75rem 0;
    margin-bottom: 5rem;
    overflow-y: scroll;
    height: 100%;

    @media (max-width: 1600px) {
      max-height: calc(100% - 105px);
      height: auto;
    }
    &::-webkit-scrollbar {
      width: 7px;
    }
    /* Color de fondo y radius esquinas del thumb */
    &::-webkit-scrollbar-thumb {
      visibility: visible;
      border-radius: 2rem;
      background: #cdcdcd;
      transition: .25s;

      &:hover, &:active {
        background: #afafaf;
      }
    }
  }
  .title {
    font-size: 1.2rem;
    line-height: 1.5rem;
    width: 60%;
  }

  .text-label {
    position: relative;
    margin: auto 0;
    z-index: 5;
  }

  .img_box {
    position: relative;
    width: 100%;
    height: 200px;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      margin: 0 auto 1rem;
      border-radius: 1rem;
      border: 1px solid #cdcdcd;
      background-color: #fff;
      object-fit: contain;
      object-position: center;
    }
  }
  .carrusel-description {
    margin: 1rem auto;
    font-size: 14px;
  }
  .lg {
    width: 100%;
  }
  .btn-botton {
    width: 100%;
    padding: 1.25rem;
    background-color: #fff;
    border-top: 1px solid #cdcdcd;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
  .stars_sm {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 5;

    .stars {
      width: 0.6rem;
      margin: 0 0.15rem;
    }
  }
  .options_container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
  }
  .variant_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    input[type="radio"] {
      display: none;
    }
    span {
      margin-bottom: 8px;
    }
    label {
      width: 35px;
      height: 35px;
      margin: 0 0.25rem;

      .img-variant {
        border-radius: 0.25rem;
        border: 1px solid #cdcdcd;
        background-color: #fff;
        cursor: pointer;
        overflow: hidden;
        width: 35px;
        height: 35px;
        padding: 0.25rem;
        object-fit: cover;
        object-position: center;
        transition: 0.25s;
        &:hover {
          transition: 0.25s;
          transform: scale(1.5);
        }
      }
    }
    input[type="radio"]:checked + label > .img-variant {
      border: 1px solid #181818 !important;
    }
    &_options {
      display: flex;
    }
  }
  .this {
    height: 35px;
  }
  .variant {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    &_options {
      display: flex;
    }
    span {
      margin-bottom: 8px;
    }
  }
  .notAvailable {
    background: #f2f2f2 !important;
    border-color: #cdcdcd !important;
    color: #cdcdcd !important;
    cursor: not-allowed;
  }
}
.recomendado-label {
  background-color: #8ae04d;
  color: #ffff;
  border: none;
}
.label-variant {
  font-weight: bold;
}

// confirm add to cart
.confirm-label {
  opacity: 0;
  // visibility: hidden;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 65px;
  left: 0;
  color: #fff;
  background-color: #8ae04d;
  padding: 1rem;
  z-index: 5;
  transition: 0.5s;

  img {
    width: 1rem;
    margin-right: 0.5rem;
  }

  label {
    cursor: default;
    margin: 0;
  }
}

.stars {
  width: 0.75rem !important;
  height: auto !important;
  border: none !important;
  margin: auto 0.1rem !important;
}

// .carousel_n {
//   max-width: 300px;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;

//   .slider_box {
//     justify-content: center;
//   }
//   label.btn-text-custom.text-center.my-3 {
//     display: flex;
//     justify-content: center;
//     font-weight: 400 !important;
//   }
//   .original-position-offer {
//     position: absolute;
//     top: .5rem;
//     left: 1rem;
//   }
// }
</style>
