<template>
  <section v-if="modal" class="info">
    <div class="info_header">
      <h3 class="mb-3">
        <span @click="setShowClientInfo(false)" class="click mr-2">{{
          `<-`
        }}</span
        >Información
      </h3>
    </div>
    <div class="info_body">
      <div class="scroll_box main-scroll px-0">
        <div class="item-1 mb-3">
          <div class="row">
            <div class="col-6 pl-0">
              <p class="text_sm">Estado</p>
            </div>
            <div class="col-6 px-0">
              <p class="text_sm normal capitalize">{{ modal.estado }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 pl-0">
              <p class="text_sm">Asignado</p>
            </div>
            <div class="col-6 px-0">
              <p class="text_sm normal">
                {{ modal && modal.agent ? modal.agent : 'Sin asignar'
                }}<span
                  v-if="
                    (selectedChat && selectedChat.client_state == 'tomado') ||
                    (conversation && conversation.state == 'taken')
                  "
                  @click="$emit('sendDeriveAgent', true)"
                  class="i-btn i-btn-sm i-derive ml-3"
                ></span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 pl-0">
              <p class="text_sm">Área</p>
            </div>
            <div class="col-6 px-0">
              <p class="text_sm normal capitalize">
                {{ modal.category || conversation?.category || 'General' }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 pl-0">
              <p class="text_sm">Inicio</p>
            </div>
            <div class="col-6 px-0">
              <p class="text_sm normal">
                {{
                  $moment(modal.created_at).format('DD / MM / YYYY - HH:MM') ||
                  '-'
                }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 pl-0">
              <p class="text_sm">Pregunta inicial</p>
            </div>
            <div class="col-6 px-0">
              <p class="txt-ellipsis-3 text_sm normal">
                {{ modal.question || '-' }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 pl-0">
              <p class="text_sm">Ubicación</p>
            </div>
            <div class="col-6 px-0">
              <p class="txt-ellipsis-3 text_sm normal">
                {{
                  modal && modal.custom
                    ? modal.custom && modal.custom.special_routes
                      ? modal.custom.special_routes.at(-1)
                      : '-'
                    : conversation?.custom?.special_routes.at(-1)
                }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 pl-0">
              <p class="text_sm">Navegador</p>
            </div>
            <div class="col-6 px-0">
              <p class="text_sm normal">{{ modal.navegator || '-' }}</p>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="label-box" @click="showDataClient = !showDataClient">
            <label
              :class="{
                'w-100 arrow mb-0': true,
                'arrow-2': showDataClient,
              }"
              >Datos del cliente</label
            >
          </div>
          <SlideYUpTransition :duracion="200" :key="showDataClient">
            <div v-show="showDataClient" class="mt-4 w-100">
              <div class="row">
                <div class="col-6 pl-0">
                  <label for="input-name" class="text_sm t-regular"
                    ><span class="i-txt i-txt-xs i-txt-user mr-1"></span
                    >Nombre</label
                  >
                </div>
                <div class="col-6 px-0">
                  <input
                    placeholder="Nombre del cliente"
                    maxlength="20"
                    class="info-edit-input text_sm"
                    v-model.trim="modal.name"
                    @change="updateLead"
                    id="input-name"
                  />
                  <span class="i-btn i-edit i-btn-sm e-hide"></span>
                </div>
              </div>
              <div class="row">
                <div class="col-6 pl-0">
                  <label for="input-corp" class="text_sm t-regular"
                    ><span class="i-txt i-txt-xs i-txt-corp mr-1"></span
                    >Empresa</label
                  >
                </div>
                <div class="col-6 px-0">
                  <input
                    placeholder="Agregar empresa"
                    maxlength="20"
                    class="info-edit-input text_sm"
                    v-model.trim="modal.company"
                    @change="updateLead"
                    id="input-corp"
                  />
                  <span class="i-btn i-edit i-btn-sm e-hide"></span>
                </div>
              </div>
              <div class="row">
                <div class="col-6 pl-0">
                  <label for="input-city" class="text_sm t-regular"
                    ><span class="i-txt i-txt-xs i-txt-location mr-1"></span
                    >Ciudad</label
                  >
                </div>
                <div class="col-6 px-0">
                  <input
                    placeholder="Agregar ciudad"
                    maxlength="20"
                    class="info-edit-input text_sm"
                    v-model.trim="modal.city"
                    @change="updateLead"
                    id="input-city"
                  />
                  <span class="i-btn i-edit i-btn-sm e-hide"></span>
                </div>
              </div>
              <div class="row">
                <div class="col-6 pl-0">
                  <label for="input-mail" class="text_sm t-regular"
                    ><span class="i-txt i-txt-xs i-txt-mail mr-1"></span
                    >Correo</label
                  >
                </div>
                <div class="col-6 px-0">
                  <input
                    placeholder="Agregar correo"
                    maxlength="30"
                    class="info-edit-input text_sm"
                    v-model.trim="modal.email"
                    @change="updateLead"
                    id="input-mail"
                  />
                  <span class="i-btn i-edit i-btn-sm e-hide"></span>
                </div>
              </div>
              <div class="row">
                <div class="col-6 pl-0">
                  <label for="input-phone" class="text_sm t-regular"
                    ><span class="i-txt i-txt-xs i-txt-phone mr-1"></span
                    >Teléfono</label
                  >
                </div>
                <div class="col-6 px-0">
                  <div class="d-flex">
                    <IconFlag
                      v-if="modal.phone"
                      classes="mt-1 mr-2"
                      :phoneNumber="modal.phone"
                      :key="modal.phone"
                    />
                    <input
                      placeholder="Agregar teléfono"
                      maxlength="20"
                      class="info-edit-input text_sm link"
                      v-model.trim="modal.phone"
                      @change="updateLead"
                      @key.enter="blur()"
                      id="input-phone"
                      type="number"
                    />
                    <span class="i-btn i-edit i-btn-sm e-hide"></span>
                  </div>
                </div>
              </div>
            </div>
          </SlideYUpTransition>
        </div>
        <!-- <div class="item">
          <label class="w-100 arrow mb-0">Carro de compra</label>
        </div> -->
        <div v-if="files && files.length > 0" class="item">
          <div class="label-box">
            <label class="w-100 mb-0"
              >Archivos adjuntos<span
                class="i-btn i-btn-sm ml-2 px-2"
                @click="showFiles = !showFiles"
                >{{ files.length }}</span
              ></label
            >
            <button
              @click="
                $emit('refreshFiles', selectedChat), (showFiles = !showFiles)
              "
              class="btn-select static text_sm my-0"
            >
              <i class="i-base i-btn-sm ml-0 mr-1 i-eye"></i>
              Mostrar
            </button>
          </div>
        </div>
        <div class="item">
          <div
            class="label-box"
            :key="notes.length"
            @click="showNotes = !showNotes"
          >
            <label class="w-100 mb-0"
              >Notas<span
                v-if="notes && notes.length > 0"
                class="i-btn i-btn-sm ml-2 px-2"
                >{{ notes.length }}</span
              ></label
            >
            <button
              @click="showNewNote = true"
              class="btn-select static text_sm my-0"
            >
              <span class="mr-1">+</span>Nueva
            </button>
          </div>
          <SlideYUpTransition :duracion="200" :key="showNotes">
            <div v-if="notes.length > 0" v-show="showNotes" class="w-100">
              <div class="scroll_box mt-3 px-0">
                <div
                  v-for="(note, index) in notes"
                  :key="index"
                  class="note_box"
                >
                  <span
                    @click="deleteNote(note.id)"
                    class="i-btn i-delete i-btn-sm"
                  ></span>
                  <div>
                    <div class="label-box mb-1">
                      <h5 class="mb-0">{{ note.title }}</h5>
                      <span class="text_xs">{{
                        $moment(note.updated_at).format('HH:MM - DD/MM/YY')
                      }}</span>
                    </div>
                    <p class="text_sm mb-0">{{ note.body }}</p>
                  </div>
                </div>
              </div>
            </div>
          </SlideYUpTransition>
        </div>
        <div
          v-if="limitedRoutesToShow && limitedRoutesToShow.length > 0"
          class="item"
        >
          <label
            @click="showRoutes = !showRoutes"
            :class="{ 'w-100 arrow mb-0': true, 'arrow-2': showRoutes }"
            >Historial de navegación</label
          >
          <SlideYUpTransition :duracion="200" :key="showRoutes">
            <div v-show="showRoutes" class="mt-4 w-100">
              <div
                v-for="(route, index) in limitedRoutesToShow"
                :key="index"
                class="d-flex"
              >
                <a
                  :class="{
                    text_sm: true,
                    link: limitedRoutesToShow.at(-1),
                  }"
                  :href="`https://${route}`"
                  target="_blank"
                  >{{ route }}</a
                >
              </div>
            </div>
          </SlideYUpTransition>
        </div>
      </div>
    </div>
    <AdjuntarArchivos
      v-if="files && files.length > 0"
      :showFiles="showFiles"
      :numberFiles="files.length"
      :conversation_id="
        selectedChat && selectedChat.databot_live
          ? selectedChat.databot_live.conversation_id
          : ''
      "
      @close="showFiles = false"
    />
    <AddNewNote
      :newNote="newNote"
      :showModal="showNewNote"
      @closeModal="showNewNote = false"
      @save="saveNewNote"
    />
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { SlideYUpTransition, SlideXLeftTransition } from 'vue2-transitions';
import AdjuntarArchivos from '@/components/AdjuntarArchivos';
import IconFlag from '@/components/IconFlag';
import AddNewNote from '@/components/AddNewNote';
import dashboard_api from '@/dashboard_api';
import dashboard_api_v2 from '../../dashboard_api_v2';

export default {
  props: [
    'modal',
    'selectedChat',
    'files',
    'show_client_info',
    'leadId',
    'conversation',
    'isDatabotLive3',
  ],

  components: {
    SlideYUpTransition,
    SlideXLeftTransition,
    AdjuntarArchivos,
    AddNewNote,
    IconFlag,
  },

  data() {
    return {
      showFiles: false,
      showRoutes: false,
      showNewNote: false,
      showNotes: false,
      showDataClient: false,
      notes: [],
      newNote: {
        title: '',
        body: '',
      },
      // editNote: {
      //   title: "",
      //   body: "",
      // },
    };
  },
  watch: {
    selectedChat(val) {
      if (val) {
        this.getNotes();
      }
    },
    'modal.name': function (newValue) {
      if (newValue == '--') this.modal.name = ''; // esto es para sicc
    },
    deep: true,
  },
  computed: {
    ...mapState(['activeChatRooms', 'roomToken', 'selected_bot']),

    lastRoute() {
      return this.modal.custom && this.modal.custom.special_routes.length > 0
        ? this.modal.custom.special_routes.at(-1)
        : '';
    },
    limitedRoutesToShow() {
      return this.modal && this.modal.custom
        ? this.modal.custom.special_routes
          ? this.modal.custom.special_routes.length > 3
            ? this.modal.custom.special_routes.slice(
                this.modal.custom.special_routes.length - 3,
              )
            : this.modal.custom.special_routes
          : []
        : [];
    },
  },
  methods: {
    ...mapMutations([
      'setShowClientInfo',
      'setSelectedChat',
      'setActiveChatRooms',
    ]),

    // editNote(note) {
    //   const self = this;
    //   dashboard_api
    //     .patch(`/lead_notes/${note.id}`, {
    //       title: note.title,
    //       body: note.body,
    //       lead_id: self.selectedChat.databot_live.lead_id,
    //     })
    //     .then((result) => {
    //       self.$swal({
    //         icon: "success",
    //         title: "Nota actualizada",
    //         timer: 1000,
    //         showConfirmButton: false,
    //       });
    //     });
    //   self.ShowEditNote = false;
    // },
    deleteNote(id) {
      this.$swal({
        title: '¿Eliminar nota?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          dashboard_api.delete(`/lead_notes/${id}`).then((res) => {
            this.notes = this.notes.filter((e) => e.id != id);
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: 'Nota eliminada',
              timer: 1000,
              showConfirmButton: false,
            });
          });
        }
      });
    },

    async getNotes() {
      if (this.selectedChat.databot_live || this.leadId) {
        let resp = (
          await dashboard_api.get(
            `lead/all_data/${
              this.leadId || this.selectedChat.databot_live.lead_id
            }`,
          )
        ).data;
        this.notes = resp.lead.lead_notes.reverse();
        // console.log("notes-->", this.notes);
      }
    },
    saveNewNote() {
      const self = this;
      let title = self.newNote.title || 'Sin título';
      let body = self.newNote.body.trim() || 'Nota vacía';

      dashboard_api
        .post('/lead_notes', {
          title,
          body,
          lead_id: self.selectedChat.databot_live.lead_id,
        })
        .then((result) => {
          self.notes.unshift(result.data);
          self.$swal({
            icon: 'success',
            title: 'Nota guardada',
            timer: 1000,
            showConfirmButton: false,
          });
        });
      self.showNewNote = false;
      self.newNote.title = '';
      self.newNote.body = '';
      console.log('Push nueva nota', self.notes);
    },

    AddNota() {
      this.ShowNota = !this.ShowNota;
      this.ShowNavDrop = false;
    },
    ShowDrop() {
      this.ShowNavDrop = !this.ShowNavDrop;
      this.ShowNota = false;
    },
    async updateLead() {
      console.log('save modal-->', this.modal);
      // in case databotlive3, first check if lead exist
      let lead;
      if (this.isDatabotLive3 && !this.conversation.lead_id) {
        const { name, city, company, phone, email, rut } = this.modal;
        if (!this.conversation.lead_id && (phone || email || rut)) {
          lead = await this.$store.dispatch(
            'leadsModule/showByEmailOrPhoneOrRut',
            {
              botId: this.selected_bot.id,
              phone,
              email,
              rut,
            },
          );
          // change modal to found lead
          this.modal = lead;
          console.log('🐞 LOG HERE lead found:', lead);
        }
        // update conversation with temporal data or found lead
        let conversationPayload = {
          id: this.conversation.id,
          data: {
            custom: {
              ...(this.conversation.custom || {}),
              phone,
              name,
              city,
              company,
            },
            lead_name: name
          },
        };
        if (lead) {
          conversationPayload.data.lead_id = lead.id;
          this.conversation.lead_id = lead.id;
        }
        await this.$store.dispatch(
          'conversationsModule/update',
          conversationPayload,
        );
      }

      if (!this.isDatabotLive3 || this.conversation?.lead_id) {
        dashboard_api_v2
          .put(`/leads/${this.modal.id}`, { lead: this.modal })
          .then((updateLeadResponse) => {
            this.$swal({
              toast: true,
              icon: 'success',
              text: '¡Guardado!',
              position: 'top-end',
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: false,
            });
            this.$emit('sendUpdateModal', this.modal);
            // Actualizar tarjeta seleccionada
            if (!this.isDatabotLive3) {
              this.selectedChat.databot_live.lead = this.modal;
            }
          })
          .catch((error) => console.log(error));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  width: 100%;
  height: 100%;

  &_header {
    padding: 0.5rem 2rem 1rem;

    @media (max-width: 1600px) {
      padding: 0.5rem 1rem 1rem;
    }
    @media (max-width: 960px) {
      padding: 0.5rem 2rem 1rem;
    }
  }
  &_body {
    padding: 0;
  }
  .text_sm {
    color: #767676;
    margin-bottom: 0.75rem;
  }
  .scroll_box {
    transition: 0.25s;
    height: auto;
    padding: 0 0.5rem 0 1rem;
    overflow-x: hidden;
    overflow-y: scroll !important;

    &::-webkit-scrollbar-thumb {
      visibility: hidden;

      &:hover,
      &:active {
        visibility: visible;
      }
    }
  }
  .item-1 {
    padding: 0 2rem;

    @media (max-width: 1600px) {
      padding: 0 1rem;
    }
    @media (max-width: 960px) {
      padding: 0 2rem;
    }
  }
  .item {
    width: 100%;
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #e8e8e8;

    &:last-child {
      border-bottom: 1px solid #e8e8e8;
    }
    @media (max-width: 1600px) {
      padding: 1rem;
    }
    @media (max-width: 960px) {
      padding: 1.5rem 2rem;
    }
  }
  .info-edit-input {
    width: auto;
    color: #181818;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    cursor: pointer;

    @media (max-width: 1600px) {
      width: 120px;
    }
    @media (max-width: 1279px) {
      width: 85%;
    }

    &:hover {
      transition: 0.25s;
      & ~ .e-hide {
        transition: 0.25s;
        opacity: 100% !important;
        visibility: visible !important;
      }
    }
    &:focus {
      padding: 0.1rem 0.5rem;
      border: 1px solid #2981ef;
    }
  }
  .click {
    background-color: transparent;
    padding: 0.1rem 0.3rem;
    border-radius: 0.5rem;

    &:hover {
      background-color: #f2f2f2;
    }
  }
  .note_box {
    position: relative;
    padding: 0.75rem 0;
    border: 1px solid transparent;
    border-radius: 1rem;
    transition: 0.25s;
    border-color: transparent;
    z-index: 1;
    cursor: pointer;

    &:hover {
      padding: 0.75rem 1rem;
      background-color: #f2f2f2;

      p {
        color: #181818;
      }
      .i-delete {
        opacity: 1;
      }
    }
    .i-delete {
      opacity: 0;
      position: absolute;
      background-color: #fff;
      top: 0.5rem;
      right: 0;
      z-index: 5;
    }
  }
  .main-scroll {
    height: 750px;

    @media (max-width: 1600px) {
      height: 600px;
    }
  }
}
</style>
