<template>
  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex flex-column justify-content-center">
      <div class="d-flex justify-content-center">
        <p 
          class="my-auto mr-4" 
          :class="page != 1 && 'allowed'"
          :style="`cursor: ${page == 1 ? 'not-allowed' : 'pointer'}`" 
          @click="page != 1 && loadConversations(1)"
        >
          1
        </p>
        <div class="number-input my-auto">
          <button 
            class="btn-left left-arrow py-1 px-3" 
            type="button" 
            id="button-addon1" 
            @click="page != 1 && loadConversations(Number(page) - 1)" 
            :disabled="page == 1"
          ></button>
          <div>
            <input 
              class="input_page m-0" 
              :class="{ input_error: errorInputPage }" 
              type="number" 
              :max="pages"
              v-model="input_page" 
              @keyup="inputPageSubmit" 
              error="true" 
            />
          </div>
          <button 
            class="btn-right right-arrow py-1 px-3" 
            type="button" 
            id="button-addon2" 
            :disabled="page == pages"
            @click="page != pages && loadConversations(Number(page) + 1)"
          ></button>
        </div>
        <p 
          class="my-auto ml-4" 
          :class="page != pages && 'allowed'" 
          :style="`cursor: ${page == pages ? 'not-allowed' : 'pointer'}`" 
          @click="page != pages && loadConversations(pages)"
        >
          {{ pages }}
        </p>
      </div>
    </div>
    <select 
      name="items" 
      class="select_text my-1" 
      :value="itemsPerPage"
      @change="loadConversations(1, $event.target.value)"
    >
      <option value="15">15 chats por página</option>
      <option value="30">30 chats por página</option>
      <option value="50">50 chats por página</option>
    </select>
  </div>
</template>
<script>
export default {
  props: {
    page: {
      type: Number,
      default: 1,
    },
    pages: {
      type: Number,
      default: 1000,
    },
    itemsPerPage: {
      type: String,
      default: 15
    }
  },
  data() {
    return {
      errorInputPage: false,
      input_page: this.page
    }
  },
  watch: {
    page(val) {
      this.input_page = val;
    }
  },
  methods: {
    loadConversations(page, itemPerPage = this.itemsPerPage) {
      this.input_page = page;
      this.$emit("changePage", page);
      this.$emit("changeItemsPerPage", itemPerPage);
    },
    inputPageSubmit(e) {
      this.errorInputPage = false;
      if (e.key === 'Enter' || e.keyCode === 13) {
        if (this.input_page >= 1 && this.input_page <= this.pages) {
          this.loadConversations(Number(this.input_page));
        } else {
          this.errorInputPage = true
        };
      }
    },
  }
}
</script>
<style></style>