<template>
  <div class="message-options">
    <div
      :class="{
        'dropdown-menu-bot': options.length > 3,
        'horizontal-options': isBooleanSelection,
        'right-aligned': !isBooleanSelection
      }"
    >
      <div v-if="options.length > 3" class="dropdown-content-bot">
        <button
          v-for="(option, optionIndex) in options"
          :key="optionIndex"
        >
          {{ option.label }}<span class="arrow"></span>
        </button>
      </div>
      <button
        v-else
        v-for="(option, optionIndex) in options"
        :key="optionIndex"
        :class="{
          'option-button': true,
          'ml-2 mb-2': options.length == 2,
          'custom-bottom': options.length == 3,
        }"
      >
        {{ option.label }}<span class="arrow"></span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    options() {
      return this.message.payload.options;
    },
    isBooleanSelection() {
      return (
        this.options.length === 2 &&
        this.options[0].label
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0301]/, '') === 'si' &&
        this.options[1].label.toLowerCase() === 'no'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.message-options {
  max-width: 450px;
  width: 100%;
  margin: 0;

  .dropdown-menu-bot {
    bottom: auto !important;
    top: 100% !important;
    width: 100% !important;
    margin-bottom: .5rem;
  }
  .dropdown-content-bot {
    padding: 0 !important;
    width: 100% !important;
    overflow: hidden !important;
    border: 1px solid #bfdbff !important;
    position: relative !important;
    display: -webkit-box !important;
    border-radius: 1rem !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;

    button,
    a {
      text-align: left;
      color: #181818 !important;
      background-color: #EFF6FF !important;
      border-bottom: 1px solid #bfdbff !important;
      padding: 0.75rem 1rem;

      &:last-child {
        border-bottom: none !important;
      }
    }

    a {
      text-decoration: none !important;
    }
    button {
      border: none;
      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
  .option-button {
    display: flex;
    max-width: 450px !important;
    width: 100% !important;
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 0;
    border-radius: 0.5rem;
    transition: 0.3s ease-in-out all;
    background-color: #EFF6FF !important;
    border: 1px solid #bfdbff !important;
    padding: 0.75rem;
    text-decoration: none;
    color: #181818;

    &:hover {
      border: 1px solid #cdcdcd;
    }
    .arrow {
      margin-left: 0.5rem;
      width: 0;
      height: 0;
      position: relative;
      border-color: transparent #181818;
      border-style: solid;
      border-width: 4px 0 4px 5px;
      right: 0;
    }
  }
  .right-aligned {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .custom-bottom {
    margin-bottom: .5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
