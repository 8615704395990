<template>
  <Modal
    class="modal-2"
    ancho="600"
    alto="500"
    :show="showModal"
    @close="$emit('closeModal', false)"
  >
    <template slot="header">
      <h3></h3>
    </template>
    <template slot="content">
      <section class="conversaciones-archivadas">
        <div class="col-lg-12">
          <label class="mb-4"
            ><i class="fab fa-whatsapp" />Conversaciones archivadas</label
          >
          <div class="row justify-content-between">
            <div class="col-md-8 pl-0">
              <p>
                Puedes desarchivar las conversaciones y volver a contestar a tus
                usuarios de WhatsApp.
              </p>
            </div>
            <div class="col-md-4 d-flex justify-content-end pr-0">
              <input
                type="search"
                class="input_search-sm mx-0"
                placeholder="Buscar"
                v-model="searchChat"
              />
            </div>
          </div>
          <SpinnerLoading class="position-relative h-100 py-4" v-if="loading" />
          <template
            v-else-if="conversationFilter && conversationFilter.length == 0"
          >
            <div class="col-lg-12 px-0">
              <div class="info_box bg_grey mt-5">
                <p>No tienes conversaciones archivadas</p>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="custom_table mt-5">
              <div class="scroll_box">
                <div
                  v-for="conversation in conversationFilter"
                  :key="conversation.id"
                  class="row item justify-content-between align-items-center"
                >
                  <div class="col-sm-4 px-0">
                    <!-- <label class="mb-0">{{conversation.databot_live.lead.name || 'Cliente'}}</label> -->
                    <label class="mb-0">{{
                      conversation.lead_name || 'Cliente'
                    }}</label>
                  </div>
                  <div class="col-sm-4 px-0">
                    <p class="mb-0">
                      {{
                        $moment(conversation.created_at).format(
                          'DD/MM/YYYY - hh:mm a',
                        )
                      }}
                    </p>
                  </div>
                  <div class="col-sm-4 d-flex justify-content-end px-0">
                    <button
                      class="btn btn-base sm my-0"
                      @click="desarchivar(conversation)"
                    >
                      Desarchivar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Pagination
              class="mt-4"
              :itemsPerPage="itemsPerPage"
              :page="page"
              :pages="pages"
              @changePage="page = $event"
              @changeItemsPerPage="itemsPerPage = $event"
            />

          </template>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Pagination from './Pagination.vue';

export default {
  props: ['showModal', 'closeModal'],

  components: {
    Pagination,
  },

  data() {
    return {
      archivedConversations: [],
      loading: false,
      searchChat: '',
      itemsPerPage: "15",
      page: 1,
      pages: 1
    };
  },

  mounted() {
    this.getArchivedChats();
  },

  computed: {
    ...mapState(['user', 'selected_bot']),

    conversationFilter() {
      return this.archivedConversations
    }
  },
  watch: {
    async page(val){
      if(this.searchChat){
        await this.onSearchChat(this.searchChat, val, true)
      } else {
        await this.getArchivedChats()
      }
    },
    async itemsPerPage() {
      if(this.searchChat){
        await this.onSearchChat(this.searchChat, this.page)
      } else {
        await this.getArchivedChats()
      }
    },
    searchChat(val){
      this.onSearchChat(val, 1)
    },
  },
  methods: {
    async getArchivedChats() {
      this.loading = true;
      const res = await this.$store.dispatch(
        'conversationsModule/listArchivedConversations',
        {
          botId: this.selected_bot.id,
          agentId: this.user.id,
          filters: {
            bot_id: this.selected_bot.id,
            archived: true,
            agentId: this.user.id,
          },
          items: this.itemsPerPage,
          page: this.page
        },
      );
      this.archivedConversations = res.conversations
      this.pages = res.pages
      this.loading = false;
    },
    async onSearchChat(searchChat, page, changePage = false){
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(async() => {
        this.loading = true
        this.searchChat = searchChat
        this.page = page
        if(this.searchChat){
          const res = await this.$store.dispatch('conversationsModule/searchArchivedConversations', {
            bot_id: this.selected_bot.id,
            search_text: this.searchChat,
            page: this.page,
            items: this.itemsPerPage
          })
          this.archivedConversations = res.conversations
          this.pages = res.pages
        } else {
          await this.getArchivedChats()
        }
        this.loading = false
      }, changePage ? 0 : 600);
    },
    desarchivar(chat) {
      this.$swal({
        title: '¿Desarchivar conversación?',
        text: 'Podrás volver a ponerte en contacto con este usuario.',
        icon: 'info',
        showConfirmButton: true,
        confirmButtonText: 'Confirmar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.archivedConversations = this.archivedConversations.filter(
            (item) => item.id != chat.id,
          );
          this.$emit('updateChats', chat);
          console.log('archivedConversations-->', this.archivedConversations);

          if (this.archivedConversations.length == 0) {
            this.$emit('closeModal', false);
          }
          // update using store module
          this.$store.dispatch('conversationsModule/update', {
            id: chat.id,
            data: {
              archived: false,
            },
            addConversation: true
          });
          this.$store.commit('conversationsModule/increaseCountersByState', {
            state: chat.state,
            qty: 1
          });
          this.$swal({
            toast: true,
            icon: 'success',
            text: 'Desarchivada',
            position: 'top-end',
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: false,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.conversaciones-archivadas {
  padding: 0 1rem 2rem;
  background-color: #fff;
  border-radius: 1rem;
  text-align: left;

  .custom_table {
    border: 1px solid #cdcdcd;
    border-radius: 1rem;
    background-color: #fafafa;
    animation-name: first-fade-y-down;
    animation-duration: 0.5s;

    .item {
      margin: auto 1.5rem;
      padding: 1rem 0;
      border-bottom: 1px solid #cdcdcd;

      &:last-child {
        border-bottom: 0;
      }
    }
    .scroll_box {
      max-height: 400px;
      overflow-y: scroll !important;
      flex-grow: 1;
      margin-bottom: 0;

      &::-webkit-scrollbar-thumb {
        margin-bottom: 1rem;
      }
    }
  }
  .info_box {
    text-align: center;
    font-size: 12px;
    padding: 2rem;
    animation-name: first-fade-y-down;
    animation-duration: 0.5s;
  }
  .xs {
    width: fit-content;
  }
}
</style>
