<template>
  <div class="">
    <div id="clasificacion">
      <label class="mb-4">{{ message.text }}</label>
      <div class="d-flex align-items-center">
        <label
          v-for="option in options" :key="option.value"
          :class="{
            'mb-0': true,
            stars: starsRating
          }"
        >
          <img
            :class="{
              'mx-2': true,
              icon_emoji: !starsRating,
              icon_star: starsRating
            }"
            :src="starsRating ? '/img/icons/rating/stars/0.2-star.svg' : option.icon"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["message"],

  data() {
    return {
      options: [
        { value: 1, name: 'Malo', icon: '/img/icons/rating/1.1-bad.svg' },
        { value: 2, name: 'Regular', icon: '/img/icons/rating/2.1-meh.svg' },
        { value: 3, name: 'Útil', icon: '/img/icons/rating/3.1-what.svg' },
        { value: 4, name: 'Bueno', icon: '/img/icons/rating/4.1-nice.svg' },
        { value: 5, name: 'Buenísimo', icon: '/img/icons/rating/5.1-love.svg' },
      ],
    };
  },
  computed: {
    ...mapState(["selected_bot"]),

    starsRating() {
      return this.isParqueAraucoBot.includes(this.selected_bot.id);
    },
    isParqueAraucoBot() {
      return [1336, 1337, 1338, 1339, 1340, 1341, 1204, 1158, 1005, 1184, 1428];
    }
  }
};
</script>

<style lang="scss" scoped>
.stars {
  width: 0.75rem;
  height: auto !important;
  border: none;
  margin: auto 0.1rem;
}
.icon_emoji,
.icon_star {
  width: 2.25rem;
  transition: 0.2s;

  &-size {
    width: 2.5rem;
  }

  &:hover {
    transition: 0.2s;
    transform: scale(1.15);
  }

  &:active {
    transition: 0.2s;
    transform: scale(0.9);
  }
}
</style>

