<template>
  <section class="skill-base">
    <FadeTransition :duration="200">
      <div class="container">
        <template v-if="store_skill_base?.length > 0">
          <div class="col-lg-12">
            <h3 class="mb-3">Interacciones de plantilla: {{skill_template.label}}</h3>
            <div class="skill-nav">
              <router-link
                v-for="(item, index) in store_skill_base"
                @click="setSelectedRoute(item)"
                :class="{
                  'text-state text-state-md px-3 mr-3 mb-3': true,
                  selected: findSelectedRoute == item.name,
                }"
                :to="
                  `${item.path}?name=${item.original_name}&template=${skill_template.value}`
                "
                :key="index"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </template>
        <SpinnerLoading v-if="loading" />
        <div v-else class="row">
          <div class="col-lg-6 mb-5" v-if="selectedIntent">
            <div class="mt-5">
              <h1 class="mb-5">{{ selectedIntent.name }}</h1>
              <h3>{{ selectedIntent.title }}</h3>
              <p>
                {{
                  selectedIntent.intent_description ||
                    'Si el usuario realiza esta pregunta, el chatbot contestará:'
                }}
              </p>
              <EditInfo
                @onChangeNode="elements = $event"
                :selected="selectedFaq"
                :selectedIntent="selectedIntent"
                :bot="selected_bot"
              />
              <div class="label-box my-3">
                <button
                  :disabled="!selectedIntent.status"
                  class="btn btn-base"
                  @click="saveSkill()"
                >
                  Guardar
                </button>
                <p class="btn-text" @click="handleNextButtonClick">
                  Siguiente<i class="fas fa-arrow-right ml-2" />
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mb-5">
            <h4 class="my-5"></h4>
            <div
              class="scroll_box-cards"
              :class="{ m_bottom: faqs.length > 6 }"
            >
              <div
                v-for="(faq, index) in faqs"
                :key="index"
                class="card buefy card-click mb-3"
                :class="{ selected: selectedFaq == index }"
                @click="
                  selectedIntent = faq;
                  showSkill(index);
                "
              >
                <div class="label-box">
                  <label class="mb-0">{{ faq.title }}</label>
                  <div class="d-flex align-items-center">
                    <span class="text-state edit_hover my-0 mx-2">Editar</span>
                    <b-switch
                      @input="onChangeFaqStatus($event, faq)"
                      v-model="faq.status"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span
              v-if="faqs.length > 0"
              class="text_sm d-flex justify-content-end my-3"
              >{{ faqs.length }}
              {{ faqs.length == 1 ? 'pregunta' : 'preguntas' }}
            </span>
          </div>
        </div>
      </div>
    </FadeTransition>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import EditInfo from './components/EditInfo';
import { FadeTransition } from 'vue2-transitions';
import '@/assets/buefy_class.scss';
import intentsService from '@/services/api/intents.js';

export default {
  components: {
    FadeTransition,
    EditInfo,
  },

  data() {
    return {
      updateFaq: 0,
      loading: false,
      selectedFaq: 0,
      selectedIntent: null,
      selectedNode: null,
      selectedName: '',
      dtSections: [],
      faqs: [],
      elements: [], // aswers for selectedNode
      skills: [
        {
          name: 'Gestión de productos',
          faqs: [
            {
              title: 'Seguimiento del pedido',
              status: false,
            },
            {
              title: '¿Puedo cambiar la dirección de despacho?',
              status: false,
            },
            {
              title: 'Quiero editar mi pedido',
              status: false,
            },
            {
              title: 'Quiero cancelar el pedido',
              status: false,
            },
            {
              title: '¿Puedo ir a retirar el pedido?',
              status: false,
            },
            {
              title: '¿Qué pasa si no hay nadie para recibir el pedido?',
              status: false,
            },
            {
              title: 'Necesito la boleta de mi pedido',
              status: false,
            },
          ],
        },
        {
          name: 'Envíos y Entregas',
          faqs: [
            {
              title: '¿Tienen retiro en tienda?',
              status: false,
            },
            {
              title: 'Ubicación y horarios',
              status: false,
            },
            {
              title: '¿Con qué empresa hacen los envíos?',
              status: false,
            },
            {
              title: 'Tiempos y costos de despacho',
              status: false,
            },
            {
              title: '¿Puedo adelantar mi pedido?',
              status: false,
            },
            {
              title: '¿Despachan a ?',
              status: false,
            },
            {
              title: '¿Tienen despacho express?',
              status: false,
            },
            {
              title: 'Horarios de las entregas',
              status: false,
            },
            {
              title: '¿Puedo cambiar la dirección de despacho?',
              status: false,
            },
          ],
        },
        {
          name: 'Productos y promociones',
          faqs: [
            {
              title: '¿Tienen stock de?',
              status: false,
            },
            {
              title: '¿Cuál es el precio de? ',
              status: false,
            },
            {
              title: 'Quiero ver los mejores descuentos',
              status: false,
            },
            {
              title: 'Quiero cotizar',
              status: false,
            },
            {
              title: '¿Hay descuento para compras al por mayor?',
              status: false,
            },
            {
              title: 'Quiero ver los últimos lanzamientos',
              status: false,
            },
            {
              title: '¿Los descuentos son acumulables?',
              status: false,
            },
            {
              title: 'No me aplicó el descuento',
              status: false,
            },
          ],
        },
        {
          name: 'Cambios y Devoluciones',
          faqs: [
            {
              title: '¿Tienen retiro en tienda?',
              status: false,
            },
            {
              title: 'Ubicación y horarios',
              status: false,
            },
            {
              title: '¿Con qué empresa hacen los envíos?',
              status: false,
            },
            {
              title: 'Tiempos y costos de despacho',
              status: false,
            },
            {
              title: '¿Puedo adelantar mi pedido?',
              status: false,
            },
            {
              title: '¿Despachan a ?',
              status: false,
            },
            {
              title: '¿Tienen despacho express?',
              status: false,
            },
            {
              title: 'Horarios de las entregas',
              status: false,
            },
            {
              title: '¿Puedo cambiar la dirección de despacho?',
              status: false,
            },
          ],
        },
        {
          name: 'Medios de pago',
          faqs: [
            {
              title: '¿Tienen retiro en tienda?',
              status: false,
            },
            {
              title: 'Ubicación y horarios',
              status: false,
            },
            {
              title: '¿Con qué empresa hacen los envíos?',
              status: false,
            },
            {
              title: 'Tiempos y costos de despacho',
              status: false,
            },
            {
              title: '¿Puedo adelantar mi pedido?',
              status: false,
            },
            {
              title: '¿Despachan a ?',
              status: false,
            },
            {
              title: '¿Tienen despacho express?',
              status: false,
            },
            {
              title: 'Horarios de las entregas',
              status: false,
            },
            {
              title: '¿Puedo cambiar la dirección de despacho?',
              status: false,
            },
          ],
        },
        {
          name: 'Sucursales y Ubicaciones',
          faqs: [
            {
              title: 'Sucursal 1',
              status: false,
            },
          ],
        },
        {
          name: 'FAQs y Otros',
          faqs: [
            {
              title: '¿Tienen retiro en tienda?',
              status: false,
            },
            {
              title: 'Ubicación y horarios',
              status: false,
            },
            {
              title: '¿Con qué empresa hacen los envíos?',
              status: false,
            },
            {
              title: 'Tiempos y costos de despacho',
              status: false,
            },
            {
              title: '¿Puedo adelantar mi pedido?',
              status: false,
            },
            {
              title: '¿Despachan a ?',
              status: false,
            },
            {
              title: '¿Tienen despacho express?',
              status: false,
            },
            {
              title: 'Horarios de las entregas',
              status: false,
            },
            {
              title: '¿Puedo cambiar la dirección de despacho?',
              status: false,
            },
          ],
        },
      ],
    };
  },

  computed: {
    ...mapState(['selected_bot', 'store_skill_base', 'skill_template']),

    findSelectedRoute() {
      let filter = this.$route.query.name.replaceAll('%', ' ');
      let name;
      if (this.store_skill_base.find(el => filter.includes(el.original_name))) {
        name = this.store_skill_base.find(el =>
          filter.includes(el.original_name),
        ).name;
      } else {
        name = this.store_skill_base[0].name;
        this.$router.push({
          path: `/chatbot-center/skill-base/${name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .split(' ')
            .join('-')}`,
          query: {
            name: this.store_skill_base[0].original_name,
            template: this.skill_template.value,
          },
        });
      }
      return name;
    },
  },
  watch: {
    selected_bot(val) {
      this.skills;
    },
    skill_template: {
      immediate: true,
      handler(val) {
        console.log('JEJEJEJJE: ', this.store_skill_base);
        if (this.store_skill_base.length) {
          this.$router.push({
            path: `/chatbot-center/skill-base/${this.store_skill_base[0].name
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .split(' ')
              .join('-')}`,
            query: {
              name: this.store_skill_base[0].original_name,
              template: this.skill_template.value,
            },
          });
          this.initialize();
        }
      },
    },
    '$route.query.name': {
      immediate: true,
      handler(val) {
        console.log('here->', val);
        this.initialize();
        this.selectedFaq = 0;
      },
    },
    '$route.query.template': {
      immediate: true,
      handler(val) {},
    },
  },
  methods: {
    ...mapMutations(['setRouterSkillBase', 'setTemplateValue']),

    async initialize() {
      this.loading = true;
      console.log('CAMBIO DE VISTA: ', this.$route);
      console.log('STORE SKILL BASE: ', this.store_skill_base);
      const sectionName = this.$route.query.name;
      const temp = this.skill_template.value;
      console.log('CAMBIO DE VISTAAAAAAAAAAAA: ', sectionName, temp);
      const faqs = await this.$store.dispatch(
        'dtIntentsModule/getBySectionName',
        {
          name: sectionName,
          template: temp,
        },
      );
      if (faqs) {
        this.faqs = await Promise.all(
          faqs.map(async el => {
            const node = await this.$store.dispatch(
              'dtNodesModule/byIntentName',
              {
                name: el.intent_name,
                botId: this.selected_bot.id,
                template: temp,
              },
            );

            return {
              title: el.intent_ui_name,
              status: node[0].status,
              node_id: node[0].id,
              ...el,
              description: el.intent_description,
            };
          }),
        );
        console.log('🚀 Aqui *** -> this.faqs:', this.faqs);
        // select first faq by default
        this.selectedIntent = this.faqs[0];
      }
      this.loading = false;
    },
    selectedBotId(id) {
      this.bot_id = id;
      console.log('this.bot_id', this.bot_id);
    },
    async saveSkill() {
      console.log('Guardado 🌝', this.selectedIntent);
      console.log('NUEVO NODO: ', this.elements);
      // NOTE - Considerar cambiar cuando se agreguen más elementos al output
      console.log(
        'TEEEEXT ----------->: ',
        this.elements[0].output_nodo.values[0].text,
      );
      if (this.elements[0].output_nodo.values[0].text.trim() === '') {
        return this.$swal({
          icon: 'error',
          title: 'Falta respuesta',
          text: 'Recuerda ingresar un texto de respuesta',
          timer: 4500,
          showConfirmButton: false,
        });
      }
      await Promise.all([
        this.$store.dispatch('dtClientIntentsModule/intentCopy', {
          source_intent_name: this.selectedIntent.intent_name,
          template: this.skill_template.value,
          bot_id: this.selected_bot.id,
        }),
        this.$store.dispatch('dtClientNodesModule/nodeCopy', {
          node_id: this.elements[0].id,
          bot_id: this.selected_bot.id,
          template: this.skill_template.value,
          new_output: [this.elements[0].output_nodo],
        }),
      ]);
      this.$swal({
        icon: 'success',
        title: 'Interacción Guardada',
        text:
          'Recuerda hacer click en Actualizar chatbot para publicar tus cambios guardados',
        timer: 4500,
        showConfirmButton: false,
      });
    },
    showSkill(index) {
      this.selectedFaq = index;
    },
    handleNextButtonClick() {
      this.selectedFaq =
        this.selectedFaq < this.faqs.length - 1 ? this.selectedFaq + 1 : 0;
      this.selectedIntent = this.faqs[this.selectedFaq];
    },
    async listIntents(botId, category) {
      const faqs = (await intentsService.list(botId, { category })).data
        .payload;
      console.log('🚀 Aqui *** -> faqs', faqs);
    },
    onChangeFaqStatus(event, faq) {
      if (event === false) {
        this.$store.dispatch('dtClientNodesModule/deactivateNode', {
          bot_id: this.selected_bot.id,
          node_id: faq.node_id,
          intent_name: faq.intent_name,
        });
        this.$swal({
          icon: 'warning',
          title: 'Interacción Desactivada',
          text:
            'Recuerda hacer click en Actualizar chatbot para publicar tus cambios guardados',
          timer: 4500,
          showConfirmButton: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.skill-base {
  position: relative;
  width: 100%;

  .text-state {
    width: fit-content !important;
    text-transform: none;
  }
  .card {
    background-color: #fafafa;

    &:hover {
      background-color: #fff;
    }
    label {
      position: relative;
      z-index: 1;
    }
  }
  .card.card-click label {
    color: #767676;
    font-weight: 400;
  }
  .card.card-click.selected {
    background-color: #fff;

    label {
      color: #181818;
      font-weight: 700;
    }
  }

  .edit_hover {
    opacity: 0;
    position: absolute;
    background-color: #fff;
    z-index: 1;
    right: 3.5rem;
    z-index: 5;
  }
  .card-click:hover div .edit_hover {
    opacity: 1;
  }
  .card-click:hover div label {
    cursor: pointer;
  }
  .skill-nav {
    display: flex;
    flex-wrap: wrap;
    margin: auto 0;
    padding: 0.5rem 0 2rem;
    border-bottom: 1px solid #e8e8e8;
  }
  .selected {
    transition: 0.5s;
    border-color: #2981ef;
    background-color: #fff;
    color: #2981ef !important;
  }
}
</style>
