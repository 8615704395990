import { render, staticRenderFns } from "./AdjuntarArchivos.vue?vue&type=template&id=dc677178&scoped=true"
import script from "./AdjuntarArchivos.vue?vue&type=script&lang=js"
export * from "./AdjuntarArchivos.vue?vue&type=script&lang=js"
import style0 from "./AdjuntarArchivos.vue?vue&type=style&index=0&id=dc677178&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc677178",
  null
  
)

export default component.exports