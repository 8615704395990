import { render, staticRenderFns } from "./MessageSticker.vue?vue&type=template&id=53da1907&scoped=true"
import script from "./MessageSticker.vue?vue&type=script&lang=js"
export * from "./MessageSticker.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53da1907",
  null
  
)

export default component.exports