<template>
  <div class="message-inner">
    <a :href="message.payload.url" target="_blank"
      ><img class="bg_img mb-3" :src="message.payload.url"
    /></a>
    <p class="text_xs">{{ messageTime(message.created_at) }}</p>
  </div>
</template>

<script>
import { formatDateRelative } from "@/utils/utils";

export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    messageTime(date) {
      return formatDateRelative(date);
    },
  }
};
</script>

<style scoped></style>