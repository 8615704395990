<template>
  <section class="change-business-type">
    <label>¿Deseas cambiar el área comercial?</label>
    <p>
      Las respuestas se relacionan directamente con el área comercial de tu chatbot. ¡Asegurate de estar en el área correcta!.
    </p>
    <div class="row pt-4">
      <div
        v-for="item in businessTypes"
        :key="item.id"
      >
        <div
          v-if="!item.custom"
          class="card"
          :class="[{ active: actualBusinessType === item.name }]"
          @click="selectItem(item)"
        >
          <input
            type="radio"
            :id="item.id"
            v-model="selectedBusiness"
            :value="item.name"
          />
          <img class="icon" :src="item.icon" :alt="item.name" />
          <p class="name mb-0">{{ item.name }}</p>
        </div>
      </div>
    </div>
    <hr class="my-5">
  </section>
</template>

<script>
import dashboard_api from "@/dashboard_api.js";

export default {
  props: [
    'currentBusinessType'
  ],
  data() {
    return {
      loading: false,
      businessTypes: [],
      selectedBusiness: null,
      SelectedOptionBox: true,
      show: false,
      actualBusinessType: this.currentBusinessType,
    };
  },
  created() {
    this.getBusinessTypes();
  },

  methods: {
    // curretBusinessfind() {
    //   // for (const select_item of this.businessTypes) {
    //   //   console.log('name ->', select_item.name);
    //   // }
    //   switch (this.currentBusinessType) {
    //     case "Ecommerce": {
    //       return console.log('-> Ecommerce 2 <-');
    //     }
    //     case "Otro": {
    //       return this.businessTypes;
    //     }
    //     default: {
    //       console.log('nada');
    //     }
    //   }
    // },
    selectItem(item) {
      this.SelectedOptionBox = item;
      for (const select_item of this.businessTypes) {
        select_item.show = false;
        // select_item.name.includes(this.currentBusinessType);

        // console.log('includes ->', this.currentBusinessType);
      }
      this.actualBusinessType = item.name;
      item.show = !item.show;
    },

    getBusinessTypes() {
      dashboard_api.get("/business_types/all_not_custom").then((response) => {
        this.businessTypes = response.data;

        this.businessTypes = this.businessTypes.map((businessType) => ({
          ...businessType,
          show: false,
        }));
      });
      // this.my_register = this.registerData;
    },
  }
};
</script>

<style lang="scss" scoped>
.change-business-type {
  width: 100%;

  .card {
    width: 80px;
    height: 80px;
    padding: 1rem 0 0.5rem;
    margin: auto 1rem 1rem auto;
    text-align: center;
    border-radius: .5rem;
    transition: 0.5s;
    position: relative;
    border: 1px solid #e8e8e8;
    cursor: pointer;

    &:hover {
      background-color: #fafafa;
    }
    &:focus {
      outline: none;
      background-color: #fafafa;
    }
    .icon {
      height: 2rem;
      margin: auto;
    }
    .name {
      font-size: 10px;
      color: #181818;
      font-weight: 700;
      margin-top: 0.25rem;
    }
    input {
      width: auto;
      position: absolute;
      box-shadow: none !important;
      top: 5px;
      right: 5px;
      opacity: 0;
    }
  }
  .active {
    position: relative;
    background-color: #fafafa;
    transition: 0.5s;

    &::before {
      content: "";
      position: absolute;
      top: 10px;
      right: 10px;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 100%;
      background-color: #2981ef;
      // box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      background-image: url("/img/icons/check-solo.svg");
      background-repeat: no-repeat, repeat;
      background-position: left 5px top 50%, 0 0;
      background-size: 0.65rem;
    }
  }
}
</style>
