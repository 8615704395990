<template>
  <div v-if="this.message" class="message-inner">
    <div
      v-if="
        message.payload &&
          message.payload.reply_to &&
          message.payload.reply_to.story
      "
      class="mb-3 d-flex flex-column justify-content-center m-auto"
    >
      <p class="text_sm mb-3">Hice un comentario en esta historia</p>
      <img
        class="story"
        :src="message.payload.reply_to.story.url"
        @error="onImageError"
        ref="storyMedia"
      />
      <hr class="my-2" />
    </div>
    <pre class="mb-2"><p v-html="$parseMarkdown(message.text)"></p></pre>
    <p class="text_xs">
      {{ messageTime(message.created_at) }}
    </p>
  </div>
</template>

<script>
import { formatDateRelative } from '@/utils/utils';

export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    grouped: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    messageTime(date) {
      return formatDateRelative(date);
    },
    onImageError() {
      // If the image fails to load, replace it with a video element
      this.$refs.storyMedia.outerHTML = `<video controls style="height: 150px" src="${this.$refs.storyMedia.src}"></video>`;
    },
  },
};
</script>

<style lang="scss" scoped>
.story {
  height: 350px;
  border-radius: .5rem;
}</style>
