var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.selected_bot.id},[((!_vm.modal && !_vm.messages) || _vm.isLoadingMessages)?_c('SpinnerLoading'):[(!_vm.selectedChat)?_c('section',{staticClass:"no-data"},[_c('div',{staticClass:"space"},[_c('img',{attrs:{"src":"/img/icons/23-no-info.svg","alt":""}}),_c('span',{staticClass:"my-4"},[_vm._v("Para ver y contestar en tiempo real, selecciona una conversación.")])])]):_c('FadeTransition',{attrs:{"duracion":100}},[(!_vm.messages)?_c('SpinnerLoading'):_c('section',{staticClass:"view-chat"},[(_vm.modal)?_c('div',{staticClass:"chat-head"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mobile",on:{"click":function($event){return _vm.$emit('closeMobile')}}},[_c('span',{staticClass:"i-base px-2 py-1 mr-3 negrita"},[_vm._v(_vm._s(`<-`))])]),_c('div',[_c('h3',{staticClass:"d-flex align-items-center mb-0"},[(_vm.modal.channel == 'instagram')?_c('span',{staticClass:"i-base i-instagram mr-2"}):(_vm.isWspChannel)?_c('i',{staticClass:"fab fa-whatsapp"}):_c('i',{key:_vm.activeClient,class:{
                    'fas fa-circle': true,
                    disconected: !_vm.selectedChat.user_online,
                  }}),_vm._v(" "+_vm._s(_vm.toCapitalize( _vm.selectedChat?.lead_name || (!_vm.modal.name || _vm.modal.name == '--' ? 'Cliente' : _vm.modal.name), ))+" "),(_vm.modal.channel == 'instagram' && _vm.modal.custom)?[(_vm.modal.custom.ig_username?.length > 0)?_c('span',{staticClass:"mx-1"},[_vm._v("-")]):_vm._e(),_c('span',{staticClass:"ml-1 normal text_sm"},[_vm._v(_vm._s(_vm.modal.custom.ig_username))])]:_vm._e()],2),(_vm.isWspChannel)?_c('p',{staticClass:"text_sm link mb-0 ml-4"},[_vm._v(" "+_vm._s(_vm.formatNumberPhone ? '+' + _vm.formatNumberPhone : 'Sin teléfono')+" "),(_vm.isWspChannel && _vm.modal.phone)?_c('IconFlag',{attrs:{"classes":"ml-2","phoneNumber":_vm.modal.phone}}):_vm._e()],1):(
                  _vm.modal.custom &&
                  _vm.modal.custom.special_routes &&
                  _vm.modal.custom.special_routes?.length > 0
                )?_c('a',{class:{
                  'text_sm link mb-0 ml-4 txt-custom-w-sm d-block': true,
                  'txt-custom-w': _vm.show_client_info,
                },attrs:{"target":"_blank","href":_vm.lastRoute}},[_vm._v(_vm._s(_vm.lastRoute || '-')+" ")]):_vm._e(),(_vm.modal.channel == 'instagram' && _vm.modal.custom)?_c('span',{staticClass:"text_sm link mb-0 ml-4"},[_vm._v(" "+_vm._s((_vm.modal.custom.ig_follower_count || 0) + ' Seguidores')+" ")]):_vm._e()])]),_c('div',{staticClass:"btn_box row desktop"},[(
                _vm.selectedChat.client_state == 'tomado' ||
                _vm.selectedChat.state === 'taken'
              )?_c('button',{staticClass:"btn-select my-0",on:{"click":function($event){return _vm.$emit('sendDeriveAgent', true)}}},[_c('i',{staticClass:"i-base i-derive mx-0 mr-2"}),_vm._v(" Derivar ")]):_vm._e(),(_vm.modal.channel !== 'Chatbot')?_c('button',{staticClass:"btn-select my-0 ml-3",on:{"click":function($event){return _vm.$emit('sendToArchive')}}},[_c('i',{staticClass:"i-base i-archive mx-0 mr-2"}),_vm._v("Archivar ")]):_vm._e(),_c('button',{class:{
                'btn-select my-0 ml-3': true,
                'btn-select-active': _vm.show_client_info,
              },on:{"click":function($event){return _vm.setShowClientInfo(!_vm.show_client_info)}}},[_c('i',{staticClass:"i-base i-client i-btn-sm mx-0 mr-2"}),_vm._v("Información ")]),(
                _vm.checkActiveChat ||
                _vm.$store.state.conversationsModule.selectedState == 'taken'
              )?_c('button',{staticClass:"btn-select static my-0 ml-3",on:{"click":function($event){return _vm.$emit('sendConnectAgent', false)}}},[_c('i',{staticClass:"i-base i-check i-btn-sm mx-0 mr-2"}),_vm._v("Terminar ")]):_vm._e()]),_c('div',{staticClass:"btn_box row align-items-center mobile"},[(_vm.selectedChat.client_state == 'tomado')?_c('button',{staticClass:"btn-select py-1 my-0 ml-2",on:{"click":function($event){return _vm.$emit('sendDeriveAgent', true)}}},[_c('i',{staticClass:"i-base i-derive mx-0"})]):_vm._e(),(_vm.modal.channel !== 'Chatbot')?_c('button',{staticClass:"btn-select py-1 my-0 ml-2",on:{"click":function($event){return _vm.$emit('sendToArchive')}}},[_c('i',{staticClass:"i-base i-archive mx-0"})]):_vm._e(),_c('button',{class:{
                'btn-select my-0 ml-2 py-1': true,
                'btn-select-active': _vm.show_client_info,
              },on:{"click":function($event){return _vm.setShowClientInfo(!_vm.show_client_info)}}},[_c('i',{staticClass:"i-base i-client i-btn-sm mx-0"})]),(_vm.checkActiveChat)?_c('button',{staticClass:"btn-select static py-1 my-0 ml-2",on:{"click":function($event){return _vm.$emit('sendConnectAgent', false)}}},[_c('i',{staticClass:"i-base i-check i-btn-sm mx-0"})]):_vm._e()]),_c('FadeTransition',{attrs:{"duracion":200}},[(_vm.isWspChannel)?_c('div',{staticClass:"timer_label"},[(_vm.lastClientMessageDate)?_c('countdown',{staticStyle:{"white-space":"nowrap","font-weight":"700","color":"#181818"},attrs:{"time":_vm.timeRemaining > 0 ? _vm.timeRemaining : 0},on:{"end":_vm.countdownEnd},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.hours || '00')+":"+_vm._s(props.minutes || '00')+":"+_vm._s(props.seconds || '00')+" ")]}}],null,false,136408927)}):_vm._e(),_c('span',{staticClass:"ml-3"},[_vm._v(" Al terminar el tiempo, tendrás que comunicarte con este cliente enviando un mensaje de plantilla. ")])],1):_vm._e()])],1):_vm._e(),_c('div',{class:{
            'item card-body': true,
            'pt-6': _vm.isWspChannel,
          },attrs:{"id":"chat-container"}},[(_vm.modal.id)?[_c('ChatMessages',{attrs:{"user":_vm.user,"bot":_vm.selected_bot,"getImgBot":_vm.imgBot,"messages":_vm.messages,"modal":_vm.modal}}),_c('MessageBotTyping',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTyping),expression:"isTyping"}],attrs:{"from":"Watson"}}),_c('div',{staticClass:"end-of-chat"})]:_vm._e()],2),_c('FadeTransition',{key:_vm.selectedChat.id,attrs:{"duracion":200}},[_c('div',{staticClass:"card-footer-2"},[(
                !_vm.checkActiveChat &&
                _vm.$store.state.conversationsModule.selectedState !== 'taken'
              )?_c('div',{key:_vm.checkActiveChat},[(_vm.isWspChannel && _vm.timeRemaining < 1)?_c('button',{staticClass:"btn btn-base second",on:{"click":function($event){_vm.showPlantillas = true}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_vm._v("Enviar plantilla ")]):_c('button',{staticClass:"btn btn-base second",attrs:{"disabled":!_vm.modal.id},on:{"click":function($event){return _vm.$emit('sendConnectAgent', true)}}},[_vm._v(" Responder ")])]):[(_vm.isWspChannel && _vm.timeRemaining < 1)?_c('button',{staticClass:"btn btn-base second",on:{"click":function($event){_vm.showPlantillas = true}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_vm._v("Enviar plantilla ")]):_vm._e(),_c('div',{staticClass:"p-relative w-100 row justify-content-center"},[_c('div',{staticClass:"col-sm-2"}),_c('div',{staticClass:"col-sm-8"},[(_vm.on_drag_element)?_c('div',[_c('input',{class:{
                        'input_drop-file-box': true,
                        'droped-file': _vm.on_drag_element && _vm.paste_file.name,
                        'file-loading': _vm.loading_img_paste
                      },attrs:{"type":"file","id":"file-input","accept":_vm.acceptFile},on:{"change":function($event){return _vm.dropFile($event)}}})]):_c('div',{class:{textarea_box: true, disabled: _vm.show_input_text || _vm.loading_img_paste},on:{"click":function($event){_vm.showEmojis = false}}},[(_vm.loading_img_paste)?_c('SpinnerLoading'):[(_vm.show_input_text)?_c('span',{staticClass:"p-text-custom text-center"},[_vm._v("Presiona "),_c('span',{staticClass:"link"},[_vm._v("enter")]),_vm._v(" para enviar")]):_vm._e(),_c('div',{key:_vm.refresh,class:{div_input: true, img_format: _vm.show_input_text},attrs:{"contenteditable":"true","placeholder":"Escribe aquí","id":"paste"},on:{"input":_vm.textImgInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.preventLine.apply(null, arguments)}}})]],2)]),_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"i-box"},[(_vm.hideItems)?[_c('span',{class:{
                          'i-btn icon_item': true,
                          'i-close btn-select-active': _vm.showEmojis,
                          'i-emoji': !_vm.showEmojis,
                        },on:{"click":function($event){_vm.showEmojis = !_vm.showEmojis}}}),_c('VEmojiPicker',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEmojis),expression:"showEmojis"}],on:{"select":_vm.selectEmoji}}),_c('IconFileUpload',{attrs:{"bot":_vm.selected_bot,"chat":_vm.selectedChat},on:{"result":function($event){return _vm.sendMessageFile($event)}}})]:[_c('span',{staticClass:"i-btn i-delete p-delete px-3",on:{"click":_vm.deletePasteImg}})],_c('span',{staticClass:"i-btn icon_item i-btn-second i-send",on:{"click":_vm.sendChatMessage}})],2)])])]],2)])],1)],1)],_c('EnviarPlantilla',{attrs:{"show":_vm.showPlantillas,"templates":_vm.templates,"values":[
      {
        id: _vm.modal.id,
        phone: _vm.modal.phone,
        name: _vm.modal.name || 'Cliente',
        custom: {},
        session_id: '',
      },
    ],"bot_id":_vm.selected_bot.id},on:{"close":function($event){_vm.showPlantillas = false},"sendTemplateToChat":_vm.sendTemplateToChat}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }