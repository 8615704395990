<template>
  <div :key="selected_bot.id">
    <SpinnerLoading v-if="(!modal && !messages) || isLoadingMessages" />
    <template v-else>
      <section v-if="!selectedChat" class="no-data">
        <div class="space">
          <img src="/img/icons/23-no-info.svg" alt="" />
          <span class="my-4"
            >Para ver y contestar en tiempo real, selecciona una
            conversación.</span
          >
        </div>
      </section>
      <FadeTransition v-else :duracion="100">
        <SpinnerLoading v-if="!messages" />
        <section v-else class="view-chat">
          <div v-if="modal" class="chat-head">
            <div class="d-flex">
              <div @click="$emit('closeMobile')" class="mobile">
                <span class="i-base px-2 py-1 mr-3 negrita">{{ `<-` }}</span>
              </div>
              <div>
                <h3 class="d-flex align-items-center mb-0">
                  <span
                    v-if="
                      modal.channel == 'instagram' &&
                      !conversationMetadata?.profile_pic
                    "
                    class="i-base i-instagram mr-2"
                  />
                  <img
                    v-else-if="conversationMetadata?.profile_pic"
                    :src="conversationMetadata.profile_pic"
                    alt=""
                    class="mr-2 msg-profile"
                  />
                  <i v-else-if="isWspChannel" class="fab fa-whatsapp" />
                  {{
                    toCapitalize(
                      conversationMetadata?.name ||
                        (!modal.lead_name || modal.lead_name == '--'
                          ? 'Cliente'
                          : modal.lead_name),
                    )
                  }}
                  <i
                    v-if="!isWspChannel"
                    :key="activeClient"
                    :class="{
                      'fas fa-circle ml-2': true,
                      disconected: !selectedChat.isUserOnline,
                    }"
                  />
                  <template v-if="modal.channel == 'instagram' && modal.custom">
                    <span
                      v-if="conversationMetadata?.ig_username?.length > 0"
                      class="mx-1"
                      >-</span
                    >
                    <span class="ml-1 normal text_sm">{{
                      conversationMetadata?.ig_username
                    }}</span>
                  </template>
                </h3>
                <p v-if="isWspChannel" class="text_sm link mb-0 ml-4">
                  {{
                    formatNumberPhone ? '+' + formatNumberPhone : 'Sin teléfono'
                  }}
                  <IconFlag
                    v-if="isWspChannel && modal.phone"
                    classes="ml-2"
                    :phoneNumber="modal.phone"
                  />
                </p>
                <a
                  v-else-if="
                    modal.custom &&
                    modal.custom.special_routes &&
                    modal.custom.special_routes?.length > 0
                  "
                  target="_blank"
                  :href="lastRoute"
                  :class="{
                    'text_sm link mb-0 txt-custom-w-sm d-block': true,
                    'txt-custom-w': show_client_info,
                  }"
                  >{{ lastRoute || '-' }}
                </a>
                <span
                  v-if="modal.channel == 'instagram' && modal.custom"
                  class="text_sm link mb-0 ml-4"
                >
                  {{
                    (conversationMetadata?.ig_follower_count || 0) +
                    ' Seguidores'
                  }}
                </span>
              </div>
            </div>
            <div class="btn_box row desktop">
              <button
                v-if="
                  selectedChat.client_state == 'tomado' ||
                  selectedChat.state === 'taken'
                "
                @click="$emit('sendDeriveAgent', true)"
                class="btn-select my-0"
              >
                <i class="i-base i-derive mx-0 mr-2" />
                Derivar
              </button>
              <button
                v-if="modal.channel !== 'Chatbot'"
                @click="$emit('sendToArchive')"
                class="btn-select my-0 ml-3"
              >
                <i class="i-base i-archive mx-0 mr-2" />Archivar
              </button>
              <button
                @click="setShowClientInfo(!show_client_info)"
                :class="{
                  'btn-select my-0 ml-3': true,
                  'btn-select-active': show_client_info,
                }"
              >
                <i class="i-base i-client i-btn-sm mx-0 mr-2" />Información
              </button>
              <button
                v-if="
                  selectedChat.client_state == 'tomado' ||
                  selectedChat.state === 'taken'
                "
                @click="$emit('sendConnectAgent', false)"
                class="btn-select static my-0 ml-3"
              >
                <i class="i-base i-check i-btn-sm mx-0 mr-2" />Terminar
              </button>
            </div>
            <!-- Mobile -->
            <div class="btn_box row align-items-center mobile">
              <button
                v-if="
                  selectedChat.client_state == 'tomado' ||
                  selectedChat.state === 'taken'
                "
                @click="$emit('sendDeriveAgent', true)"
                class="btn-select py-1 my-0 ml-2"
              >
                <i class="i-base i-derive mx-0" />
              </button>
              <button
                v-if="modal.channel !== 'Chatbot'"
                @click="$emit('sendToArchive')"
                class="btn-select py-1 my-0 ml-2"
              >
                <i class="i-base i-archive mx-0" />
              </button>
              <button
                @click="setShowClientInfo(!show_client_info)"
                :class="{
                  'btn-select my-0 ml-2 py-1': true,
                  'btn-select-active': show_client_info,
                }"
              >
                <i class="i-base i-client i-btn-sm mx-0" />
              </button>
              <button
                @click="$emit('sendConnectAgent', false)"
                class="btn-select static py-1 my-0 ml-2"
              >
                <i class="i-base i-check i-btn-sm mx-0" />
              </button>
            </div>
            <FadeTransition :duracion="200">
              <div
                v-if="isWspChannel || channel === 'instagram'"
                class="timer_label"
              >
                <countdown
                  v-if="timeRemaining"
                  style="white-space: nowrap; font-weight: 700; color: #181818"
                  :time="timeRemaining > 0 ? timeRemaining : 0"
                  @end="countdownEnd"
                >
                  <template slot-scope="props"
                    >{{ props.hours || '00' }}:{{ props.minutes || '00' }}:{{
                      props.seconds || '00'
                    }}
                  </template>
                </countdown>
                <span class="ml-3">
                  {{
                    channel === 'whatsapp'
                      ? 'Al terminar el tiempo, tendrás que comunicarte con este cliente enviando un mensaje de plantilla.'
                      : 'Puedes comunicarte con el cliente en esta ventana de tiempo'
                  }}
                </span>
              </div>
            </FadeTransition>
          </div>
          <div
            id="chat-container"
            :class="{
              'item card-body': true,
              'pt-6': isWspChannel,
            }"
          >
            <template v-if="modal.id">
              <ChatMessages
                :user="user"
                :bot="selected_bot"
                :getImgBot="imgBot"
                :messages="messages"
                :modal="modal"
                :conversation="selectedChat"
              />
              <MessageBotTyping v-show="isTyping" from="Watson" />
              <div class="end-of-chat"></div>
            </template>
          </div>
          <InputDatabotLive
            :key="count"
            :selectedChat="selectedChat"
            :isWspChannel="isWspChannel"
            :timeRemaining="timeRemaining"
            :modal="modal"
            :textInput="'textInput'"
            :message_chat="message_chat"
            :showEmojis="showEmojis"
            @setShowPlantillas="showPlantillas = $event"
            @sendConnectAgent="$emit('sendConnectAgent', true)"
            @PreventLine="PreventLine"
            @selectEmoji="message_chat = $event"
            @setMessageFile="sendMessageFile($event)"
            @sendChatMessage="sendChatMessage"
            @imTyping="message_chat = $event"
            @updateEmojis="showEmojis = $event"
            @sendMessageAudio="$emit('sendMessageAudio', $event)"
            />
        </section>
      </FadeTransition>
    </template>
    <EnviarPlantilla
      :show="showPlantillas"
      @close="showPlantillas = false"
      @sendTemplateToChat="sendTemplateToChat"
      :templates="templates"
      :values="[
        {
          id: modal?.id,
          phone: modal?.phone,
          name: modal?.name || 'Cliente',
          custom: {},
          session_id: '',
        },
      ]"
      :bot_id="selected_bot.id"
    />
  </div>
</template>

<script>
import EnviarPlantilla from '@/components/EnviarPlantilla.vue';
import countdown from '@chenfengyuan/vue-countdown';
import {
  whatsappTimeRemaining,
  capitalizeFirstLetter,
  isImage,
} from '@/utils/utils.js';
import { mapState, mapMutations } from 'vuex';
import dashboard_api from '@/dashboard_api.js';
import ChatMessages from '@/views/V2/Conversations/components/ChatMessages.vue';
import MessageBotTyping from '@/components/chat/MessageBotTyping.vue';
import AddNewNote from '@/components/AddNewNote';
import IconFlag from '@/components/IconFlag';
import { FadeTransition } from 'vue2-transitions/dist/vue2-transitions.cjs';
import InputDatabotLive from '@/views/V2/DatabotLive/components/InputDatabotLive';

export default {
  props: [
    'modal',
    'agents',
    'messages',
    'conversation_id',
    'lastClientMessageDate',
    'isTyping',
    'selectedChat',
    'isLoadingMessages',
    'lead',
  ],
  components: {
    countdown,
    EnviarPlantilla,
    ChatMessages,
    MessageBotTyping,
    FadeTransition,
    AddNewNote,
    IconFlag,
    InputDatabotLive
  },
  data() {
    return {
      imgBot: '',
      message_chat: '',
      templates: [],
      showPlantillas: false,
      typing: false,
      isWspChannel: false,
      showEmojis: false,
      text_template: [],
      count: 0
    };
  },
  mounted() {
    this.initialize();
    this.getChatbotInfo();
  },
  watch: {
    async selectedChat(val) {
      if (val && val.id) {
        this.isWspChannel = val.channel == 'whatsapp';
      }
    },
    selected_bot(val) {
      this.initialize();
    },
  },
  computed: {
    ...mapState(['user', 'selected_bot', 'show_client_info', 'activeClient']),

    lastRoute() {
      return this.modal.custom && this.modal.custom.special_routes?.length > 0
        ? 'http://' + this.modal.custom.special_routes.at(-1)
        : '';
    },
    formatNumberPhone() {
      const phone = this.modal.phone || this.lead?.phone;
      if (phone) {
        let width = phone.toString().split('');
        width.splice(3, 0, ' ');

        return width.join('');
      }
    },
    timeRemaining() {
      return this.selectedChat.last_message_date
        ? whatsappTimeRemaining(this.selectedChat.last_message_date)
        : null;
    },
    conversationMetadata() {
      return this.selectedChat.custom;
    },
    channel() {
      return this.selectedChat.channel;
    },
  },
  methods: {
    ...mapMutations(['setShowClientInfo']),

    async sendTemplateToChat(template) {
      this.text_template = [];

      if (template && template.components.length > 0) {
        let header = template.components.find((el) => el.type == 'HEADER');
        let body = template.components.find((el) => el.type == 'BODY');
        let footer = template.components.find((el) => el.type == 'FOOTER');
        let actions = template.components.find((el) => el.type == 'BUTTONS');

        if (header) {
          if (header.custom_parameters && header.custom_parameters.length > 0) {
            await header.custom_parameters.forEach((el, index) => {
              el == '$lead.nombres'
                ? (el = this.modal.name)
                : el == '$lead.correo'
                ? (el = this.modal.email)
                : el == '$lead.phome'
                ? (el = this.modal.phone)
                : el;
              this.text_template.push(
                header.text.replace(`{{${index + 1}}}`, el),
              );
            });
          } else this.text_template.push(header.text);
        }
        if (body) {
          if (body.custom_parameters && body.custom_parameters.length > 0) {
            await body.custom_parameters.forEach((el, index) => {
              el == '$lead.nombres'
                ? (el = this.modal.name)
                : el == '$lead.correo'
                ? (el = this.modal.email)
                : el == '$lead.phome'
                ? (el = this.modal.phone)
                : el;
              this.text_template.push(
                body.text.replace(`{{${index + 1}}}`, el),
              );
            });
          } else this.text_template.push(body.text);
        }
        if (footer) this.text_template.push(footer.text);
        if (actions && actions.buttons.length > 0) {
          actions.buttons.forEach((el) => {
            this.text_template.push(el.text);
          });
        }
        this.message_chat = this.text_template.join('. ').toString().trim();
        this.$emit('sendMessage', this.message_chat);
      }
    },
    toCapitalize(val) {
      return capitalizeFirstLetter(val);
    },
    async getChatbotInfo() {
      let chatbot = (
        await dashboard_api.get(`/bot/showedit/${this.selected_bot.id}`)
      ).data;

      this.imgBot =
        chatbot.bot_avatar || chatbot.bot_logo_url || 'img/brand/0-default.svg';
    },
    async initialize() {
      try {
        let whatsappBot = (
          await dashboard_api.get(
            `/bot_credential/get_by_bot_id/${this.selected_bot.id}`,
          )
        ).data;
        if (whatsappBot.length > 0) {
          this.templates = (
            await dashboard_api.get(
              `/payroll_messages/by_bot/${this.selected_bot.id}`,
            )
          ).data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    sendMessageFile(file) {
      this.$emit('sendMessageFile', {
        url: file.url,
        type: isImage(file.url) ? 'image' : 'file',
      });
    },
    sendMessageAudio(audio) {
      this.$emit('sendMessageAudio', { url: audio.url });
    },

    // Evitar salto de linea
    PreventLine(event) {
      if (event.keyCode == 13 && !event.shiftKey) {
        event.preventDefault();
        this.sendChatMessage();
        this.showEmojis = false;
      }
    },
    sendChatMessage() {
      if (this.message_chat.trim().length == 0) return;
      this.$emit('sendMessage', this.message_chat);
      this.message_chat = this.message_chat.trim();
      this.message_chat = '';
      this.count++;
    },
    InsertTemplateInTheChat(template, room) {
      const div = document.createElement('div');
      div.innerHTML = template;
      // agregado if porque ahora al entrar a dblive nos unimos a todos los rooms activos
      if (document.getElementById(`chat-${room}`)) {
        document.getElementById(`chat-${room}`).appendChild(div);
      }
    },
    newLine(e) {
      const caret = e.target.selectionStart;
      e.target.setRangeText('\n', caret, caret, 'end');
      this.text = e.target.value;
    },
    countdownEnd() {
      this.$emit('countDownEnd');
    },
  },
};
</script>

<style lang="scss">
.view-chat {
  .emoji-picker {
    position: absolute;
    width: 500px;
    top: -460px;
    right: 0;
    background-color: #fff !important;
    border-radius: 0.5rem !important;
    box-shadow: 10px 10px 40px #bfdbff;

    .border {
      border-color: transparent !important;
    }
  }
  #Categories {
    background-color: #fff !important;
  }
}
</style>

<style lang="scss" scoped>
.view-chat {
  position: relative;
  width: 100%;
  display: grid;
  align-items: flex-start;
  grid-template-rows: 1fr calc(80.5vh - 150px) 150px;
  align-items: center;

  @media (max-width: 1600px) {
    grid-template-rows: 1fr calc(76vh - 150px) 150px;
  }
  .chat-head {
    padding: 2rem;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 1rem;

    @media (max-width: 1279px) {
      padding: 1rem 2rem;
      align-items: center;
    }
    @media (max-width: 540px) {
      padding-left: 1rem;
    }
    @media (max-width: 375px) {
      padding: 1rem;
    }
  }
  .disconected {
    color: #b2b2b2 !important;
  }
  .fa-circle {
    font-size: 0.5rem;
    color: #8ae04d;
  }
  .fa-whatsapp {
    font-size: 1.2rem;
    color: #8ae04d;
    margin-right: 0.5rem;
  }
  .card-body {
    position: relative;
    background-color: #fff;
    height: 100%;
    padding: 2rem;
    padding-right: calc(2rem - 8px);
    overflow-y: scroll;
    padding-bottom: 5rem;

    @media (max-width: 375px) {
      max-height: 100%;
      padding: 5rem 0 5rem 1rem;
      padding-right: 0.5rem;
    }
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
      border-radius: 2rem;
      background: #cdcdcd;
      transition: 0.25s;

      &:hover,
      &:active {
        visibility: visible;
        background: #afafaf;
      }
    }
  }
  .timer_label {
    position: absolute;
    width: 100%;
    top: 94px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    padding: 0.75rem;
    background-color: #fafafa;
    border-bottom: 1px solid #e8e8e8;
    color: #181818;
    z-index: 10;

    @media (max-width: 1279px) {
      top: 78px;
    }
  }
  .mobile {
    @media (min-width: 1280px) {
      display: none;
    }
  }
  .desktop {
    @media (max-width: 1279px) {
      display: none;
    }
  }
  .link {
    @media (max-width: 640px) {
      max-width: 100px;
    }
  }
  .txt-custom-w,
  .txt-custom-w-sm {
    @media (max-width: 1800px) {
      width: 220px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      // direction: rtl;
      text-align: left;
    }
    @media (max-width: 1500px) {
      width: 120px;
    }
  }
  .txt-custom-w-sm {
    @media (max-width: 375px) {
      width: 95px;
    }
  }
  .i-btn-second {
    &:hover {
      background-color: #181818;
      border-color: #181818;
    }
  }
}
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  height: calc(100vh - 80px);
  overflow-y: hidden;

  .space {
    margin: auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      width: 70%;
      text-align: center;
    }
  }
}
.up-size {
  font-size: 1.25rem;
}

.reply-message-box {
  display: block !important;
  margin-bottom: 5px;
  color: #000;
}
</style>
