<template>
  <Modal
    class="modal-2"
    alto="500"
    ancho="800"
    :show="showFiles"
    @close="$emit('close')"
  >
    <template v-if="showFiles" slot="content">
      <section class="adjuntar-archivos">
        <label class="text-left">Tienes {{ conversation_files.length }} {{ conversation_files.length > 1 ? 'archivos' : 'archivo' }}
        </label>
        <div class="card__box">
          <div class="col-lg-12">
            <div class="row justify-content-start">
              <div
                v-for="item in conversation_files"
                :key="item.id"
                class="col-lg-4 item"
              >
                <div class="item__box">
                  <a
                    :href="item.url"
                    target="_blank"
                    class="img_link"
                  >
                    <embed
                      v-if="item.mime_type !== 'application/postscript'"
                      class="item__img"
                      :src="item.url"
                      alt=""
                    />
                    <img
                      v-else
                      class="item__img"
                      src="/img/icons/11-no_file.svg"
                      alt=""
                    />
                  </a>
                  <!-- <span @click="deleteFile(item.id)" class="item__close">×</span> -->
                </div>
                <p class="item__title">{{ item.name }}</p>
                <a
                  download
                  :href="item.url"
                  :title="item.name"
                  class="btn btn-base outline sm mb-4"
                  >Descargar
                  <img
                    class="not_here"
                    :src="item.url"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
import dashboard_api from '@/dashboard_api';
import conversations_api from '@/conversations_api';

export default {
  props: ['numberFiles', 'conversation_id', 'showFiles', 'conversation_files'],

  data() {
    return {
      // conversation_files: [],
    };
  },

  watch: {
    // showFiles: {
    //   immediate: true,
    //   async handler(val) {
    //     // await this.getFiles();
    //   }
    // }
  },
  methods: {
    // async getFiles() {
    //   try {
    //     this.conversation_files = (await conversations_api.get(`/conversations/conversation_files/${this.conversation_id}`)).data;

    //     if (this.conversation_files && this.conversation_files.length > 0) {
    //       this.conversation_files.sort((a, b) => (a.id > b.id) ? -1 : 1);
    //     }
    //   } catch {
    //     console.log("no files");
    //   }
    // },

    deleteFile(id) {
      const self = this;

      self.$swal({
        title: "¿Eliminar archivo?",
        text: "No podrás recuperar el archivo eliminado.",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          dashboard_api.delete(`/conversation_files/${id}`).then((result) => {
            // self.showFiles = false
            self.$swal({
              icon: 'success',
              title: 'Archivo eliminado',
              timer: 1000,
              showConfirmButton: false,
            });
            console.log('Eliminado->', self.conversation_files);
            // self.$router.go('/');
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.adjuntar-archivos {
  width: 100%;

  .card__box {
    padding: 1rem 1.5rem 1.5rem;
    max-height: 440px;
    overflow-y: scroll;
    flex-grow: 1;
    margin-bottom: 0;

    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      visibility: visible;
      border-radius: 2rem;
      background: #cdcdcd;
      transition: .25s;

      &:hover, &:active {
        background: #afafaf;
      }
    }
    .item {
      &__close {
        position: absolute;
        z-index: 10;
        color: #fff;
        width: 2rem;
        height: 2rem;
        background-color: #b2b2b2;
        border-radius: 30rem;
        justify-content: center;
        align-items: center;
        margin: auto;
        display: flex;
        font-size: 1.25rem;
        top: -1rem;
        right: 0;
        transition: 0.5s;
        cursor: pointer;
        opacity: 0;

        &:hover {
          opacity: 1;
        }
      }
      &__box {
        display: flex;
        width: 100%;
        height: 150px;
        align-items: center;
        overflow: hidden;
        background-color: #fafafa;
        border: 1px solid #e8e8e8;
        border-radius: 1rem;
        margin-bottom: 1rem;
      }
      .img_link {
        display: flex;
        align-items: center;
        height: 100%;

        &:hover ~ .item__close {
          opacity: 1;
        }
      }
      &__img {
        width: 100%;
        transition: 0.5s;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }
      }
      .custom {
        width: 100%;
        padding: 0.5rem 1rem;
        margin: 0 auto 1rem;
        border-radius: 0 0 10px 10px;
      }
      &__title {
        font-size: 12px;
        word-break: break-word;
        // white-space: nowrap;
        margin-bottom: 1rem;
        width: 100%;
        overflow: hidden;
        max-height: 35px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
  }
  .btn-base {
    margin: 0;

    @media (max-width: 480px) {
      margin-top: 1rem;
    }
  }
  .clip {
    width: 1rem;

    @media (max-width: 480px) {
      display: none;
    }
  }
  .no_click {
    &:hover {
      cursor: initial !important;
      opacity: 1 !important;
    }
  }
  .number_box {
    padding: .25rem .75rem;
    color: #fff;
  }
  .hide {
    opacity: 0;
  }
}
</style>
