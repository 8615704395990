<template>
  <div class="pagination">
    <button
      class="btn-pagination"
      @click="actualPage = 1;"
      v-if="actualPage > 1"
    >
      1
    </button>
    <button
      class="btn-pagination"
      @click="actualPage = actualPage - 1;"
      v-if="actualPage > 1"
    >
      <i class="fas fa-caret-left" />
    </button>
    <button class="btn-pagination active_page" @click="$emit('getItems', actualPage)" v-if="pages > 2">
      {{ actualPage }}
    </button>
    <button
      class="btn-pagination"
      @click="actualPage = actualPage + 1;"
      v-if="actualPage < pages"
    >
      <i class="fas fa-caret-right" />
    </button>
    <button
      class="btn-pagination"
      @click="actualPage = pages;"
      v-if="actualPage < pages"
    >
      {{ pages }}
    </button>
  </div>
</template>
<script>
export default {
  props: {
    pages: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      actualPage: 1,
    };
  },

  watch: {
    actualPage(newVal, oldVal) {
      console.log(oldVal, ' ', newVal);
      this.$emit('getItems', newVal);
      console.log('newVal->', newVal);
    }
  },
};
</script>
