<template>
  <div class="multiselect">
    <div v-if="!expanded" class="selectBox" @click="showCheckboxes()">
      <select>
        <option>Seleccionar {{ label || field }}</option>
      </select>
      <div class="overSelect"></div>
    </div>
    <div v-else class="selectBox" @click="showCheckboxes()">
      <select>
        <option>Seleccionar {{ label || field }}</option>
      </select>
      <div class="overSelect"></div>
    </div>
    <div class="checkbox-id" :id="field">
      <p
        v-for="(item, index) in filteredItems"
        :key="index"
        :for="index"
        class="checkboxes-item"
      >
        <input
          type="checkbox"
          v-model="selected_columns"
          :value="item.name"
          :id="index"
        />{{ item.formatted }}
      </p>
      <p for="sin_asignar" class="checkboxes-item">
        <input
          type="checkbox"
          v-model="selected_columns"
          :value="undefined"
          id="sin_asignar"
        />Otros (Sin asignar)
      </p>
    </div>
  </div>
</template>

<script>
import { localStorageGetItem, localStorageSetItem } from '@/utils/utils';

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    field: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    selectedFilters: {
      type: Object,
      default: () => {},
    },
    parent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selected_columns: [],
      expanded: false,
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    filteredItems() {
      return this.parent &&
        this.selectedFilters[this.parent] &&
        this.selectedFilters[this.parent].length > 0
        ? this.items
            .filter((item) =>
              this.selectedFilters[this.parent]
                ? this.selectedFilters[this.parent].includes(item.belongTo)
                : true,
            )
            .sort((a, b) => (a.name > b.name ? 1 : -1))
        : this.items.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
  },
  methods: {
    initialize() {
      // inicializando industrias
      this.selected_columns = localStorageGetItem('customFilters')
        ? JSON.parse(localStorageGetItem('customFilters'))[this.field].map(
            (value) => (value === null ? undefined : value),
          )
        : [];
    },
    /**
     * @description metodo para multiselect
     */ showCheckboxes() {
      const checkboxes = document.getElementById(this.field);
      checkboxes.style.display = this.expanded ? 'none' : 'block';
      this.expanded = !this.expanded;
    },
    persistSelection(selection) {
      if (!localStorageGetItem('customFilters')) {
        localStorageSetItem('customFilters', JSON.stringify(selection));
      }
      let customFilters = JSON.parse(localStorageGetItem('customFilters'));
      customFilters = { ...customFilters, ...selection };
      localStorageSetItem('customFilters', JSON.stringify(customFilters));
    },
  },
  watch: {
    selected_columns(newValue) {
      this.$emit('onSelectedFilters', {
        [this.field]: newValue,
      });
      this.persistSelection({
        [this.field]: newValue,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.multiselect {
  select {
    margin: 0.5rem 1rem 0.5rem 0;
  }
}
</style>
