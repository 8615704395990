<template>
  <div :key="selected_bot.id">
    <SpinnerLoading v-if="(!modal && !messages) || isLoadingMessages" />
    <template v-else>
      <section v-if="!selectedChat" class="no-data">
        <div class="space">
          <img src="/img/icons/23-no-info.svg" alt="" />
          <span class="my-4"
            >Para ver y contestar en tiempo real, selecciona una
            conversación.</span
          >
        </div>
      </section>
      <FadeTransition v-else :duracion="100">
        <SpinnerLoading v-if="!messages" />
        <section v-else class="view-chat">
          <div v-if="modal" class="chat-head">
            <div class="d-flex">
              <div @click="$emit('closeMobile')" class="mobile">
                <span class="i-base px-2 py-1 mr-3 negrita">{{ `<-` }}</span>
              </div>
              <div>
                <h3 class="d-flex align-items-center mb-0">
                  <span
                    v-if="modal.channel == 'instagram'"
                    class="i-base i-instagram mr-2"
                  />
                  <i v-else-if="isWspChannel" class="fab fa-whatsapp" />
                  <i
                    v-else
                    :key="activeClient"
                    :class="{
                      'fas fa-circle': true,
                      disconected: !selectedChat.user_online,
                    }"
                  />
                  <!-- {{
                    toCapitalize(
                      selectedChat?.custom?.name ||
                        (!modal.name || modal.name == '--'
                          ? 'Cliente'
                          : modal.name),
                    )
                  }} -->
                  {{
                    toCapitalize(
                      selectedChat?.lead_name ||
                        (!modal.name || modal.name == '--'
                          ? 'Cliente'
                          : modal.name),
                    )
                  }}
                  <template v-if="modal.channel == 'instagram' && modal.custom">
                    <span
                      v-if="modal.custom.ig_username?.length > 0"
                      class="mx-1"
                      >-</span
                    >
                    <span class="ml-1 normal text_sm">{{
                      modal.custom.ig_username
                    }}</span>
                  </template>
                </h3>
                <p v-if="isWspChannel" class="text_sm link mb-0 ml-4">
                  {{
                    formatNumberPhone ? '+' + formatNumberPhone : 'Sin teléfono'
                  }}
                  <IconFlag
                    v-if="isWspChannel && modal.phone"
                    classes="ml-2"
                    :phoneNumber="modal.phone"
                  />
                </p>
                <a
                  v-else-if="
                    modal.custom &&
                    modal.custom.special_routes &&
                    modal.custom.special_routes?.length > 0
                  "
                  target="_blank"
                  :href="lastRoute"
                  :class="{
                    'text_sm link mb-0 ml-4 txt-custom-w-sm d-block': true,
                    'txt-custom-w': show_client_info,
                  }"
                  >{{ lastRoute || '-' }}
                </a>
                <span
                  v-if="modal.channel == 'instagram' && modal.custom"
                  class="text_sm link mb-0 ml-4"
                >
                  {{ (modal.custom.ig_follower_count || 0) + ' Seguidores' }}
                </span>
              </div>
            </div>
            <div class="btn_box row desktop">
              <button
                v-if="
                  selectedChat.client_state == 'tomado' ||
                  selectedChat.state === 'taken'
                "
                @click="$emit('sendDeriveAgent', true)"
                class="btn-select my-0"
              >
                <i class="i-base i-derive mx-0 mr-2" />
                Derivar
              </button>
              <button
                v-if="modal.channel !== 'Chatbot'"
                @click="$emit('sendToArchive')"
                class="btn-select my-0 ml-3"
              >
                <i class="i-base i-archive mx-0 mr-2" />Archivar
              </button>
              <button
                @click="setShowClientInfo(!show_client_info)"
                :class="{
                  'btn-select my-0 ml-3': true,
                  'btn-select-active': show_client_info,
                }"
              >
                <i class="i-base i-client i-btn-sm mx-0 mr-2" />Información
              </button>
              <button
                v-if="
                  checkActiveChat ||
                  $store.state.conversationsModule.selectedState == 'taken'
                "
                @click="$emit('sendConnectAgent', false)"
                class="btn-select static my-0 ml-3"
              >
                <i class="i-base i-check i-btn-sm mx-0 mr-2" />Terminar
              </button>
            </div>
            <!-- Mobile -->
            <div class="btn_box row align-items-center mobile">
              <button
                v-if="selectedChat.client_state == 'tomado'"
                @click="$emit('sendDeriveAgent', true)"
                class="btn-select py-1 my-0 ml-2"
              >
                <i class="i-base i-derive mx-0" />
              </button>
              <button
                v-if="modal.channel !== 'Chatbot'"
                @click="$emit('sendToArchive')"
                class="btn-select py-1 my-0 ml-2"
              >
                <i class="i-base i-archive mx-0" />
              </button>
              <button
                @click="setShowClientInfo(!show_client_info)"
                :class="{
                  'btn-select my-0 ml-2 py-1': true,
                  'btn-select-active': show_client_info,
                }"
              >
                <i class="i-base i-client i-btn-sm mx-0" />
              </button>
              <button
                v-if="checkActiveChat"
                @click="$emit('sendConnectAgent', false)"
                class="btn-select static py-1 my-0 ml-2"
              >
                <i class="i-base i-check i-btn-sm mx-0" />
              </button>
            </div>
            <FadeTransition :duracion="200">
              <div v-if="isWspChannel" class="timer_label">
                <countdown
                  v-if="lastClientMessageDate"
                  style="white-space: nowrap; font-weight: 700; color: #181818"
                  :time="timeRemaining > 0 ? timeRemaining : 0"
                  @end="countdownEnd"
                >
                  <template slot-scope="props"
                    >{{ props.hours || '00' }}:{{ props.minutes || '00' }}:{{
                      props.seconds || '00'
                    }}
                  </template>
                </countdown>
                <span class="ml-3">
                  Al terminar el tiempo, tendrás que comunicarte con este
                  cliente enviando un mensaje de plantilla.
                </span>
              </div>
            </FadeTransition>
          </div>
          <div
            id="chat-container"
            :class="{
              'item card-body': true,
              'pt-6': isWspChannel,
            }"
          >
            <template v-if="modal.id">
              <ChatMessages
                :user="user"
                :bot="selected_bot"
                :getImgBot="imgBot"
                :messages="messages"
                :modal="modal"
              />
              <MessageBotTyping v-show="isTyping" from="Watson" />
              <div class="end-of-chat"></div>
            </template>
          </div>
          <FadeTransition :duracion="200" :key="selectedChat.id">
            <div class="card-footer-2">
              <div
                :key="checkActiveChat"
                v-if="
                  !checkActiveChat &&
                  $store.state.conversationsModule.selectedState !== 'taken'
                "
              >
                <button
                  v-if="isWspChannel && timeRemaining < 1"
                  class="btn btn-base second"
                  @click="showPlantillas = true"
                >
                  <i class="fas fa-paper-plane" />Enviar plantilla
                </button>
                <button
                  v-else
                  class="btn btn-base second"
                  :disabled="!modal.id"
                  @click="$emit('sendConnectAgent', true)"
                >
                  Responder
                </button>
              </div>
              <template v-else>
                <button
                  v-if="isWspChannel && timeRemaining < 1"
                  class="btn btn-base second"
                  @click="showPlantillas = true"
                >
                  <i class="fas fa-paper-plane" />Enviar plantilla
                </button>
                <div class="p-relative w-100 row justify-content-center">
                  <div class="col-sm-2"></div>
                  <div class="col-sm-8">
                    <!-- To drag file -->
                    <div v-if="on_drag_element">
                      <input
                        type="file"
                        id="file-input"
                        :accept="acceptFile"
                        :class="{
                          'input_drop-file-box': true,
                          'droped-file': on_drag_element && paste_file.name,
                          'file-loading': loading_img_paste
                        }"
                        @change="dropFile($event)"
                      />
                    </div>
                    <div v-else :class="{textarea_box: true, disabled: show_input_text || loading_img_paste}" @click="showEmojis = false">
                      <SpinnerLoading v-if="loading_img_paste" />
                      <template v-else>
                        <span v-if="show_input_text" class="p-text-custom text-center">Presiona <span class="link">enter</span> para enviar</span>
                        <div
                          :class="{div_input: true, img_format: show_input_text}"
                          :key="refresh"
                          @input="textImgInput"
                          @keydown.enter="preventLine"
                          contenteditable="true"
                          placeholder="Escribe aquí"
                          id="paste"
                        ></div>
                      </template>
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="i-box">
                      <template v-if="hideItems">
                        <span
                          @click="showEmojis = !showEmojis"
                          :class="{
                            'i-btn icon_item': true,
                            'i-close btn-select-active': showEmojis,
                            'i-emoji': !showEmojis,
                          }"
                        >
                        </span>
                        <VEmojiPicker v-show="showEmojis" @select="selectEmoji" />
                        <IconFileUpload
                          :bot="selected_bot"
                          :chat="selectedChat"
                          @result="sendMessageFile($event)"
                        />
                      </template>
                      <template v-else>
                        <span class="i-btn i-delete p-delete px-3" @click="deletePasteImg"></span>
                      </template>
                      <span
                        @click="sendChatMessage"
                        class="i-btn icon_item i-btn-second i-send"
                      ></span>
                    </div>
                  </div>
                </div>
                <!-- <label v-else for="textInput" class="textarea_box mb-0">
                  <textarea
                    cols="30"
                    rows="10"
                    id="textInput"
                    placeholder="Escribe aquí"
                    @keydown.enter="preventLine"
                    @input="$emit('imTyping', message_chat)"
                    v-model="message_chat"
                  />
                  <div class="i-box">
                    <span
                      @click="showEmojis = !showEmojis"
                      :class="{
                        'i-btn i-btn-white': true,
                        'i-close btn-select-active': showEmojis,
                        'i-emoji': !showEmojis,
                      }"
                    >
                    </span>
                    <VEmojiPicker v-show="showEmojis" @select="selectEmoji" />
                    <IconFileUpload
                      :bot="selected_bot"
                      :chat="selectedChat"
                      @result="sendMessageFile($event)"
                    />
                    <span
                      @click="sendChatMessage"
                      class="i-btn i-btn-second i-send"
                    ></span>
                  </div>
                </label> -->
              </template>
            </div>
          </FadeTransition>
        </section>
      </FadeTransition>
    </template>
    <EnviarPlantilla
      :show="showPlantillas"
      @close="showPlantillas = false"
      @sendTemplateToChat="sendTemplateToChat"
      :templates="templates"
      :values="[
        {
          id: modal.id,
          phone: modal.phone,
          name: modal.name || 'Cliente',
          custom: {},
          session_id: '',
        },
      ]"
      :bot_id="selected_bot.id"
    />
  </div>
</template>

<script>
import EnviarPlantilla from '@/components/EnviarPlantilla.vue';
import countdown from '@chenfengyuan/vue-countdown';
import { whatsappTimeRemaining, capitalizeFirstLetter } from '@/utils/utils.js';
import { mapState, mapMutations } from 'vuex';
import dashboard_api from '@/dashboard_api.js';
import ChatMessages from '@/components/ChatMessages.vue';
import MessageBotTyping from '@/components/chat/MessageBotTyping.vue';
import AddNewNote from '@/components/AddNewNote';
import { VEmojiPicker } from 'v-emoji-picker';
import IconFlag from '@/components/IconFlag';
import IconFileUpload from './IconFileUpload';
import fileUpload from './IconFileUpload';
import { FadeTransition } from 'vue2-transitions/dist/vue2-transitions.cjs';
import { locale } from 'moment';

export default {
  props: [
    'modal',
    'agents',
    'messages',
    'checkActiveChat',
    'conversation_id',
    'lastClientMessageDate',
    'isTyping',
    'selectedChat',
    'isLoadingMessages',
  ],
  components: {
    countdown,
    EnviarPlantilla,
    ChatMessages,
    MessageBotTyping,
    FadeTransition,
    AddNewNote,
    VEmojiPicker,
    IconFlag,
    IconFileUpload,
    fileUpload,
  },
  data() {
    return {
      loading_img_paste: false,
      show_input_text: false,
      imgBot: '',
      message_chat: '',
      templates: [],
      showPlantillas: false,
      typing: false,
      isWspChannel: false,
      showEmojis: false,
      text_template: [],
      total: '',
      paste_file: {},
      on_drag_element: false,
      refresh: 0,
      is_paste: false,
      acceptFile: ['image/jpg', 'image/png', 'image/jpeg', 'application/pdf', '.xlsx', '.xls'],
    };
  },
  mounted() {
    this.initialize();
    this.getChatbotInfo();
    this.globalEnter();
    this.getDragItem();
    this.getDropItem();
  },
  watch: {
    async selectedChat(val) {
      if (val?.id) this.isWspChannel = val.channel == 'Whatsapp Bot';
    },
    selected_bot(val) {
      this.initialize();
    },
    async messages(val) {
      if (val?.length) {
        await this.$nextTick();
        // scroll
        setTimeout(() => {
          const objDiv = document.getElementById('chat-container'); // es el id del contenedor del chat
          objDiv.scrollTop = objDiv.scrollHeight;
        }, 600);
      }
    },
  },
  computed: {
    ...mapState(['user', 'selected_bot', 'show_client_info', 'activeClient']),

    hideItems() {
      return !this.paste_file.name;
    },
    lastRoute() {
      return this.modal.custom?.special_routes?.length > 0
        ? 'http://' + this.modal.custom.special_routes.at(-1)
        : '';
    },
    formatNumberPhone() {
      if (this.modal.phone) {
        let width = this.modal.phone.toString().split('');
        width.splice(3, 0, ' ');

        return width.join('');
      }
    },
    timeRemaining() {
      return whatsappTimeRemaining(this.lastClientMessageDate);
    },
  },
  methods: {
    ...mapMutations(['setShowClientInfo']),

    getDropItem() {
      window.addEventListener("drop", (e) => {
        setTimeout(() => {
          if (!this.paste_file.name) {
            e.preventDefault();
            this.on_drag_element = false;
          } else {
            e.preventDefault();
            this.on_drag_element = true;
          }
        }, 1000);
      }, false);
    },
    getDragItem() {
      window.addEventListener("dragover", (e) => {
        e.preventDefault();
        if (e.isTrusted == true) this.on_drag_element = true;
      }, false);
    },
    dropFile(event) {
      if (event.target.files.length >= 1) this.paste_file = event.target.files[0];
      else this.paste_file = {};
    },
    globalEnter() {
      // Global enter to send paste img->
      document.addEventListener("keypress", (event) => {
        if ((event.keyCode == 13 && this.show_input_text) || (event.keyCode == 13 && this.on_drag_element)) this.sendChatMessage();
      });
    },
    dataUrlToFile(img_url) {
      fetch(img_url)
        .then(res => res.blob())
        .then(blob => {
          this.paste_file = new File([blob], `Captura-pantalla-${this.$moment().format('DD_MM_YYYY-HH:mm.png')}`, { type: "image/png" })
          // console.log("paste_file->", this.paste_file);
        })
    },
    processFile() {
      const self = this;
      const reader = new FileReader();
      const MAXIMO_TAMANIO_BYTES = 3000000;
      const tamanio = MAXIMO_TAMANIO_BYTES / 1000000;
      self.loading_img_paste = true;

      if (self.paste_file?.size > MAXIMO_TAMANIO_BYTES) {
        self.$swal({
          icon: "error",
          title: "Achivo muy grande",
          text: `Por favor, adjunta un achivo menor a ${tamanio} Mb.`,
        });
        self.paste_file = {};
        self.on_drag_element = false;
        self.show_input_text = false;
        self.loading_img_paste = false;
      } else if (self.paste_file?.name?.length > 0) {
        console.log("file->", self.paste_file);
        reader.readAsDataURL(self.paste_file);

        reader.onload = function () {
          dashboard_api
            .post('/conversations/process_files', {
              file: reader.result,
              name: self.paste_file?.name,
              bot_id: self.selected_bot.id,
              conversation_id: self.selectedChat.databot_live.conversation_id,
            })
            .then((response) => {
              self.$emit('sendMessage', `${response.data.url}#${self.paste_file?.name}`);
              self.$swal({
                toast: true,
                position: 'top-right',
                icon: "success",
                title: "¡Listo!",
                timer: 1000,
                showConfirmButton: false,
              });
              self.paste_file = {};
              self.loading_img_paste = false;
              self.show_input_text = false;
              self.on_drag_element = false;
            })
            .catch((error) => console.log('error ->', error));
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      }
    },
    deletePasteImg() {
      this.paste_file = {};
      this.show_input_text = false;
      this.on_drag_element = false;
      // to refresh paste div component
      this.refresh++;
    },
    textImgInput(event) {
      let div_img = document.getElementById('paste');
      this.total = event.target?.innerText.trim();

      if (event.inputType == 'insertFromPaste') {
        // Al pegar elemento y enviar mensaje el html se rompe quitando un elemento
        // key que valida si es pegado o no antes de refrescar componente o se pierde el elemento focus del div input
        this.is_paste = true;
        if (!event.target.innerText) {
          let img = div_img.querySelector('img');
          if (img) {
            this.total = '';
            this.show_input_text = true;
            img.style.cssText = "position: relative; display: flex; justify-content: center; top: -12rem; right: 0px; left: 0px; max-width: 480px; width: 100%; height: 250px; background-color: #f2f2f2; object-fit: contain; padding: 1rem; border: 1px solid rgb(205, 205, 205); border-radius: 1rem; margin: 0 auto 2rem; box-shadow: rgb(222, 237, 255) 0px 0px 60px;";
            this.dataUrlToFile(img.src);
            div_img.blur();
            div_img.removeAttribute('contenteditable');
          } else {
            this.show_input_text = false;
            this.refresh++;
          };
        } else {
          div_img.innerHTML = this.total;
        }
      } else {
        this.is_paste = false;
      }
    },
    async sendTemplateToChat(template) {
      this.text_template = [];

      if (template && template.components.length > 0) {
        let header = template.components.find((el) => el.type == 'HEADER');
        let body = template.components.find((el) => el.type == 'BODY');
        let footer = template.components.find((el) => el.type == 'FOOTER');
        let actions = template.components.find((el) => el.type == 'BUTTONS');

        if (header) {
          if (header.custom_parameters && header.custom_parameters.length > 0) {
            await header.custom_parameters.forEach((el, index) => {
              el == '$lead.nombres'
                ? (el = this.modal.name)
                : el == '$lead.correo'
                ? (el = this.modal.email)
                : el == '$lead.phome'
                ? (el = this.modal.phone)
                : el;
              this.text_template.push(
                header.text.replace(`{{${index + 1}}}`, el),
              );
            });
          } else this.text_template.push(header.text);
        }
        if (body) {
          if (body.custom_parameters && body.custom_parameters.length > 0) {
            await body.custom_parameters.forEach((el, index) => {
              el == '$lead.nombres'
                ? (el = this.modal.name)
                : el == '$lead.correo'
                ? (el = this.modal.email)
                : el == '$lead.phome'
                ? (el = this.modal.phone)
                : el;
              this.text_template.push(
                body.text.replace(`{{${index + 1}}}`, el),
              );
            });
          } else this.text_template.push(body.text);
        }
        if (footer) this.text_template.push(footer.text);
        if (actions && actions.buttons.length > 0) {
          actions.buttons.forEach((el) => {
            this.text_template.push(el.text);
          });
        }
        this.message_chat = this.text_template.join('. ').toString().trim();
        this.$emit('sendMessage', this.message_chat);
      };
    },

    toCapitalize(val) {
      return capitalizeFirstLetter(val);
    },
    selectEmoji(emoji) {
      let div_img = document.getElementById('paste');
      let em = document.createTextNode(emoji.data);
      div_img.appendChild(em);
      this.total += emoji.data;
    },
    async getChatbotInfo() {
      let chatbot = (
        await dashboard_api.get(`/bot/showedit/${this.selected_bot.id}`)
      ).data;

      this.imgBot =
        chatbot.bot_avatar || chatbot.bot_logo_url || 'img/brand/0-default.svg';
    },
    async initialize() {
      try {
        let whatsappBot = (
          await dashboard_api.get(
            `/bot_credential/get_by_bot_id/${this.selected_bot.id}`,
          )
        ).data;
        if (whatsappBot.length > 0) {
          this.templates = (
            await dashboard_api.get(
              `/payroll_messages/by_bot/${this.selected_bot.id}`,
            )
          ).data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    sendMessageFile(file) {
      this.$emit('sendMessage', file.url);
    },
    // Evitar salto de linea
    preventLine(event) {
      if (event.keyCode == 13 && !event.shiftKey) {
        event.preventDefault();
        this.sendChatMessage(event);
        this.showEmojis = false;
      }
    },
    sendChatMessage(event) {
      this.message_chat = this.total;
      if (!this.paste_file.name && this.message_chat.trim().length == 0) return;
      if (this.paste_file?.name?.length > 0) return this.processFile();
      // console.log("this.message_chat", this.message_chat);
      this.$emit('sendMessage', this.message_chat);
      this.message_chat = '';
      this.total = '';
      if (this.is_paste) this.refresh++;
      if (event) event.target.innerText = '';
    },

    InsertTemplateInTheChat(template, room) {
      const div = document.createElement('div');
      div.innerHTML = template;
      // agregado if porque ahora al entrar a dblive nos unimos a todos los rooms activos
      if (document.getElementById(`chat-${room}`)) {
        document.getElementById(`chat-${room}`).appendChild(div);
      }
    },
    scrollWindowToElement(id_element) {
      const element = document.getElementById(id_element);
      element.scrollTop = element.scrollHeight;
    },
    newLine(e) {
      const caret = e.target.selectionStart;
      e.target.setRangeText('\n', caret, caret, 'end');
      this.text = e.target.value;
    },
    countdownEnd() {
      this.$emit('countDownEnd');
    },
  },
};
</script>

<style lang="scss">
.view-chat {
  .emoji-picker {
    position: absolute;
    width: 500px;
    top: -460px;
    right: 0;
    background-color: #fff !important;
    border-radius: 0.5rem !important;
    box-shadow: 10px 10px 40px #bfdbff;

    .border {
      border-color: transparent !important;
    }
  }
  #Categories {
    background-color: #fff !important;
  }
}
</style>

<style lang="scss" scoped>
.view-chat {
  position: relative;
  width: 100%;
  display: grid;
  align-items: flex-start;
  grid-template-rows: 1fr calc(80.5vh - 150px) 150px;
  align-items: center;

  @media (max-width: 1600px) {
    grid-template-rows: 1fr calc(76vh - 150px) 150px;
  }
  .chat-head {
    padding: 2rem;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 1rem;

    @media (max-width: 1279px) {
      padding: 1rem 2rem;
      align-items: center;
    }
    @media (max-width: 540px) {
      padding-left: 1rem;
    }
    @media (max-width: 375px) {
      padding: 1rem;
    }
  }
  .disconected {
    color: #b2b2b2 !important;
  }
  .fa-circle {
    font-size: 0.75rem;
    color: #8ae04d;
    margin-right: 0.6rem;
  }
  .fa-whatsapp {
    font-size: 1.2rem;
    color: #8ae04d;
    margin-right: 0.5rem;
  }
  .card-body {
    position: relative;
    background-color: #fff;
    height: 100%;
    padding: 2rem;
    padding-right: calc(2rem - 8px);
    overflow-y: scroll;
    padding-bottom: 5rem;
    scroll-behavior: smooth;

    @media (max-width: 375px) {
      max-height: 100%;
      padding: 5rem 0 5rem 1rem;
      padding-right: 0.5rem;
    }
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
      border-radius: 2rem;
      background: #cdcdcd;
      transition: 0.25s;

      &:hover,
      &:active {
        visibility: visible;
        background: #afafaf;
      }
    }
  }
  .card-footer-2 {
    width: 100%;
    position: relative;
    padding: 2rem;
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: #fff;
    border-top: 1px solid #e8e8e8;
    z-index: 10;

    @media (max-width: 480px) {
      bottom: 3rem;
    }
    .textarea_box {
      position: relative;
      max-width: 850px;
      width: 100%;
      height: auto;
      min-height: 40px;
      max-height: 100px;
      overflow-y: scroll;
      border-radius: 1rem;
      background-color: #eff6ff;
      padding: 0.25rem;
      border: 1px solid #bfdbff;
      transition: 0.25s;
      z-index: 8;

      &.disabled {
        background-color: #ffffff !important;
        border-color: transparent !important;
        height: 53px !important;
        max-height: 100% !important;
        overflow-y: visible !important;
      }
      .bg-spinner {
        position: relative;
        display: flex;
        justify-content: center;
        top: -12rem;
        right: 0px;
        left: 0px;
        max-width: 480px;
        width: 100%;
        height: 250px;
        background-color: #fafafa;
        object-fit: contain;
        padding: 1rem;
        border: 1px solid #e8e8e8;
        border-radius: 1rem;
        margin: 0 auto 2rem;
      }
      .div_input {
        outline: none;
        width: 100%;
        height: auto;
        padding: 1rem;
        font-size: 1rem;

        &:focus {
          color: #181818;
        }
        &:empty::before {
          content: attr(placeholder);
          color: #767676;
        }
        &:empty:focus::before {
          content: "";
          display: block;
          height: 24px;
        }
      }
      .img_format {
        height: 53px !important;
        background-color: #ffffff;
      }
      .p-delete {
        position: absolute;
        top: 1rem;
        right: 1rem;
      }
      .p-text-custom {
        position: absolute;
        top: -215px;
        left: 0;
        right: 0;
        width: fit-content;
        margin: 0 auto;
        z-index: 10;
      }
    }
    .i-box {
      display: grid;
      grid-template-columns: repeat(3, 40px);
      grid-template-rows: 35px;
      column-gap: 0.5rem;
      max-height: 45px;
      height: 100%;
      right: 3rem;
      bottom: 0.5rem;
      z-index: 100;
    }
    .i-btn-white {
      background-color: #fff;
    }
  }
  .textarea_box:has(.div_input:focus) {
    border: 1px solid #2981ef !important;
  }
  .timer_label {
    position: absolute;
    width: 100%;
    top: 94px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    padding: 0.75rem;
    background-color: #fafafa;
    border-bottom: 1px solid #e8e8e8;
    color: #181818;
    z-index: 10;

    @media (max-width: 1279px) {
      top: 78px;
    }
  }
  .mobile {
    @media (min-width: 1280px) {
      display: none;
    }
  }
  .desktop {
    @media (max-width: 1279px) {
      display: none;
    }
  }
  .link {
    @media (max-width: 640px) {
      max-width: 100px;
    }
  }
  .txt-custom-w,
  .txt-custom-w-sm {
    @media (max-width: 1800px) {
      width: 220px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      direction: rtl;
      text-align: left;
    }
    @media (max-width: 1500px) {
      width: 120px;
    }
  }
  .txt-custom-w-sm {
    @media (max-width: 375px) {
      width: 95px;
    }
  }
  .i-btn-second {
    &:hover {
      background-color: #181818;
      border-color: #181818;
    }
  }
  .show {
    position: relative;
    padding: 1.25rem;
    border-radius: 0 0 1rem 1rem;
    border: 1px solid #cdcdcd;
    top: -17px;
  }
  .paste_box {
    width: 100%;
    height: 100%;
    background: #f2f2f2;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    animation-name: fade;
    animation-duration: .25s;
  }
  .input_drop-file-box {
    max-height: 100px;
    width: 100%;
    height: 100px;
    border-radius: 1rem;
    color: transparent;
    background-color: #fafafa;
    border: 1px dashed #cdcdcd;

    &::-webkit-file-upload-button {
      display: none;
    }
    &:hover {
      border-color: #2981EF !important;
    }
    &:empty::before {
      content: 'Arrastra tu archivo aquí o haz click aquí';
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      color: #767676;
      height: 50px;
      top: 20%;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .droped-file {
    background-color: #f8fbff;
    border: 1px solid #2981EF;

    &::before {
      content: '✓ Archivo cargado. Presiona enter para enviar' !important;
      color: #2981EF !important;
    }
  }
  .file-loading::before {
    content: 'Enviando archivo...' !important;
  }
}
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  height: calc(100vh - 80px);
  overflow-y: hidden;

  .space {
    margin: auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      width: 70%;
      text-align: center;
    }
  }
}
</style>
