import axios from "axios";
import environment from "@/environment.js";

export default {
  list(assistant_id) {
    return axios.get(`${environment.RASA_URL}/api/assistants/${assistant_id}/intents`);
  },
  update(assistant_id, payload) {
    return axios.put(`${environment.RASA_URL}/api/assistants/${assistant_id}/intents`, payload);
  },
  create(assistant_id, payload) {
    return axios.post(`${environment.RASA_URL}/api/assistants/${assistant_id}/intents`, payload);
  },
  delete(assistant_id) {
    return axios.delete(`${environment.RASA_URL}/api/assistants/${assistant_id}/intents`);
  }
};
