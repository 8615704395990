var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{key:_vm.selected_bot.id,class:{
    chats: true,
    'botton-line': _vm.searchedChat && _vm.searchedChat.length >= 10,
  }},[_c('div',{staticClass:"menu"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Chats")]),_c('div',{staticClass:"row"},[_c('span',{class:{
          'btn-select': true,
          'mx-1,': true,
          'btn-select-active': _vm.selected == 1,
        },on:{"click":function($event){_vm.selected = 1}}},[(_vm.db_table && _vm.db_table.length != 0)?_c('span',[_vm._v(_vm._s(_vm.db_table.length || ''))]):_vm._e(),_vm._v(" Activas")]),_c('span',{class:{
          'btn-select': true,
          'mx-1,': true,
          'btn-select-active': _vm.selected == 2,
        },on:{"click":function($event){_vm.selected = 2}}},[(_vm.activeChatRooms && _vm.activeChatRooms.length != 0)?_c('span',[_vm._v(_vm._s(_vm.activeChatRooms.length || ''))]):_vm._e(),_vm._v(" Tomadas")]),_c('span',{class:{
          'btn-select': true,
          'mx-1,': true,
          'btn-select-active': _vm.selected == 3,
        },on:{"click":function($event){_vm.selected = 3}}},[(_vm.pendingChats && _vm.pendingChats.length != 0)?_c('span',[_vm._v(_vm._s(_vm.pendingChats.length || ''))]):_vm._e(),_vm._v(" Pendientes")])]),_c('div',{staticClass:"search_menu"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchChat),expression:"searchChat"}],attrs:{"placeholder":"Buscar","type":"search"},domProps:{"value":(_vm.searchChat)},on:{"input":function($event){if($event.target.composing)return;_vm.searchChat=$event.target.value}}}),_c('span',{class:{
            'i-btn':true,
            'i-filter': !_vm.showDropdownFilter,
            'i-btn-active i-close i-st': _vm.showDropdownFilter
          },on:{"click":function($event){_vm.showDropdownFilter = !_vm.showDropdownFilter}}}),_c('MultiselectTag',{attrs:{"showDropdown":_vm.showDropdownFilter,"items":_vm.tags},on:{"sendCategoryFilter":function($event){_vm.tagsFilter = $event}}}),_c('span',{staticClass:"i-btn i-archive-b",on:{"click":function($event){return _vm.$emit('sendShowArchived', true)}}})],1)]),(_vm.loading)?_c('spinnerLoading'):[_c('FadeTransition',{key:_vm.selected,attrs:{"duracion":500}},[(_vm.searchedChat && _vm.searchedChat.length > 0)?[_c('div',{staticClass:"scroll_box"},[_vm._l((_vm.searchedChat),function(chat){return _c('div',{key:chat.id,class:{
              to_card: true,
              selected_card: _vm.selectedChat
                ? _vm.selectedChat.id === chat.id
                : false,
            },on:{"click":function($event){return _vm.generateChat(chat)}}},[(chat && chat.databot_live)?[_c('div',{staticClass:"card-box"},[(chat.channel !== 'Whatsapp Bot' && chat.channel !== 'instagram')?_c('span',{staticClass:"user-img"},[_vm._v(_vm._s((chat.databot_live.lead.name && chat.databot_live.lead.name[0]) || 'C'))]):_c('span',{class:{
                    'user-img-wsp':chat.channel === 'Whatsapp Bot', 'user-img-instagram':chat.channel === 'instagram'}}),_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8 px-0"},[(chat.databot_live.lead)?_c('label',{staticClass:"mb-0 mr-2"},[_vm._v(" "+_vm._s(!chat.databot_live.lead.name || chat.databot_live.lead.name == '--' ? `Cliente` : chat.databot_live.lead.name)+" ")]):_vm._e(),(chat.category && chat.category != 'general')?_c('span',{staticClass:"etiqueta label-p-sm mr-1"},[_vm._v(_vm._s(chat.category))]):_vm._e()]),_c('div',{staticClass:"col-4 pl-2 pr-0"},[_c('p',{staticClass:"text_sm text-right m-0"},[_vm._v(" "+_vm._s(chat.last_message && chat.last_message.updated_at ? _vm.$moment(chat.last_message.updated_at) .locale('es') .fromNow() == 'hace unos segundos' ? 'ahora' : _vm.$moment(chat.last_message.updated_at) .locale('es') .fromNow() .includes('hace') ? _vm.$moment(chat.last_message.updated_at) .locale('es') .fromNow() .replace('hace', '') : _vm.$moment(chat.last_message.updated_at) .locale('es') .fromNow() : _vm.$moment(chat.updated_at).locale('es').fromNow())+". ")])])]),_c('div',{staticClass:"label-box"},[_c('p',{staticClass:"user-message mt-2 mb-0"},[_vm._v(" "+_vm._s(chat.last_received_message || 'Sin mensaje inicial')+" ")]),((chat.channel === 'Whatsapp Bot' || chat.channel === 'instagram') && _vm.selected === 1)?_c('span',{staticClass:"i-btn i-btn-sm i-archive mb-0 mr-0",on:{"click":function($event){$event.stopPropagation();return _vm.hidePrechat(chat)}}}):_vm._e()])])])]:_vm._e()],2)}),(_vm.allChats.length == 10)?_c('div',{staticClass:"row my-4"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLoadChat),expression:"showLoadChat"}],staticClass:"text-state text-state-md mx-auto px-3",on:{"click":_vm.loadMoreChats}},[_vm._v(" Cargar más ")])]):_vm._e()],2)]:[_c('div',{staticClass:"info_box mx-3 my-4"},[_c('p',{staticClass:"text-center"},[_vm._v("Sin conversaciones")])])]],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }