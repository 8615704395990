var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message-options"},[_c('div',{class:{
      'dropdown-menu-bot': _vm.options.length > 3,
      'horizontal-options': _vm.isBooleanSelection,
      'right-aligned': !_vm.isBooleanSelection
    }},[(_vm.options.length > 3)?_c('div',{staticClass:"dropdown-content-bot"},_vm._l((_vm.options),function(option,optionIndex){return _c('button',{key:optionIndex},[_vm._v(" "+_vm._s(option.label)),_c('span',{staticClass:"arrow"})])}),0):_vm._l((_vm.options),function(option,optionIndex){return _c('button',{key:optionIndex,class:{
        'option-button': true,
        'ml-2 mb-2': _vm.options.length == 2,
        'custom-bottom': _vm.options.length == 3,
      }},[_vm._v(" "+_vm._s(option.label)),_c('span',{staticClass:"arrow"})])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }