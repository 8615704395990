<template>
  <div class="message-inner">
    <pre><p><a :href="message.payload.url" target="_blank">{{message.payload.filename || message.payload.url.split('#').pop()}}</a></p></pre>
    <p class="text_xs">
      {{ messageTime(message.created_at) }}
    </p>
  </div>
</template>

<script>
import { formatDateRelative } from "@/utils/utils";

export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    messageTime(date) {
      return formatDateRelative(date);
    },
  }
};
</script>