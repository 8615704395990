<template>
  <Modal
    class="modal-2"
    ancho="600"
    alto="500"
    :show="showModal"
    @close="$emit('closeModal')"
    >
    <template slot="header">
      <h1 class="titulo__modal mb-4"></h1>
    </template>
    <template slot="content">
      <section class="agregar-nota">
        <div class="col-lg-12">
          <label>Titulo de la nota</label>
          <input
            type="text"
            maxlength="50"
            placeholder="Ejem: Importante"
            v-model="newNote.title"
          />
          <label>Mensaje</label>
          <textarea
            class="textarea_2"
            rows="3"
            maxlength="200"
            placeholder="Escribe la nota aquí"
            v-model="newNote.body"
          />
          <p class="text_sm text-right">{{ newNote.body.length }} / 200</p>
          <div class="row justify-content-center my-4">
            <button
              @click="$emit('save')"
              class="btn btn-base text-center">Guardar
            </button>
          </div>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
export default {
  props: [
    'newNote',
    'showModal',
    'closeModal'
  ],
  data() {
    return {
      test: '',
    };
  },
};
</script>

<style lang="scss" scoped>
.agregar-nota {
  background-color: #fff;
  border-radius: 10px;
  padding: 0 1rem;
  text-align: left;

  input {
    width: 100%;
    margin: 0 0 1rem;
  }
  select {
    width: 100%;
    margin: 0 0 1rem;
  }
  .contenedor {
    padding: 0 1rem;
  }
}
</style>
