<template>
  <div>
    <a :href="message.payload.url" target="_blank"
      ><img class="bg_img mb-3" :src="message.payload.url"
    /></a>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  }
};
</script>

<style scoped></style>
