<template>
  <div class="icon-audio-upload">
    <span
      v-if="!isRecording && !isPaused"
      @click="startRecording"
      class="i-btn i-btn-sm i-btn-white i-audio"
    />
    <div v-if="isRecording || audio_ready" class="float_box">
      <span @click="deleteRecording" class="fas fa-times i-btn i-btn-white" />
      <span v-if="isRecording" @click="pauseRecording" class="fas fa-check i-btn i-btn-white active" />
      <span
       v-else-if="audio_ready"
        @click="nowCanSendMessage"
        class="i-btn i-btn-second i-send btn-send"
        ></span>
    </div>
  </div>
</template>
<script>
import conversations_api from '@/conversations_api.js';
import { mapState, mapMutations } from 'vuex';

export default {
  props: {
    conversation: {
      type: Object,
      default: () => ({}),
    },
    bot: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isRecording: false,
      isPaused: false,
      stream: null,
      mediaRecorder: null,
      chunks: [],
    };
  },
  computed: {
    ...mapState(['audio_ready'])
  },
  watch: {
  },
  methods: {
    ...mapMutations(["readyToSendAudioMessage"]),

    nowCanSendMessage() {
      console.log("stream->", this.stream);
      console.log("chunks->", this.chunks);
      console.log("mediaRecorder->", this.mediaRecorder);
      this.$emit('isRecording', false);
      this.sendAudioMessage();
    },
    async startRecording() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        // Check if microphone permission was granted
        const permissions = await navigator.permissions.query({
          name: 'microphone',
        });
        if (permissions.state === 'denied') {
          console.log('Microphone permission was denied');
          this.stream.getTracks().forEach((track) => track.stop());
          this.isRecording = false;
          this.mediaRecorder = null;
          return;
        }
        this.$emit('isRecording', true);
        this.isRecording = true;
        this.chunks = [];
        this.mediaRecorder = new MediaRecorder(this.stream);

        this.mediaRecorder.addEventListener('dataavailable', (event) => {
          this.chunks.push(event.data);
        });

        this.mediaRecorder.addEventListener('stop', async () => {
          if (this.stream) {
            this.stream.getTracks().forEach((track) => track.stop());
            // this.sendAudioMessage();
          }
        });
        this.mediaRecorder.start();
      } catch (error) {
        console.error(error);
        alert('Please grant permission to access the microphone');
      }
    },
    // stopRecording() {
    //   this.mediaRecorder.stop();
    //   this.isRecording = false;
    //   this.readyToSendAudioMessage(true);
    // },
    pauseRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;
      // this.isPaused = true;
      this.readyToSendAudioMessage(true);
    },
    // resumeRecording() {
    //   this.mediaRecorder.resume();
    //   this.isPaused = false;
    //   this.isRecording = true;
    // },
    deleteRecording() {
      // Ensure the stream is valid before accessing its tracks
      if (this.stream && this.stream.getTracks) {
        this.stream.getTracks().forEach((track) => track.stop());
      } else {
        console.warn('No stream available to stop tracks');
      }

      this.chunks = [];

      // Ensure mediaRecorder is valid and in an active state before stopping it
      if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
        this.mediaRecorder.stop();
      } else {
        console.warn('No active media recorder to stop');
      }
      this.clear();
    },
    clear() {
      this.isRecording = false;
      this.mediaRecorder = null;
      this.chunks = [];
      this.stream = null;
      this.$emit('isRecording', false);
      this.readyToSendAudioMessage(false);
    },
    async sendAudioMessage() {
      let sessions = await this.getSessions(this.conversation.id);
      const blob = new Blob(this.chunks, { type: 'audio/wav' });

      if (blob) {
        try {
          const formData = new FormData();
          formData.append('file', blob, 'audio.wav');
          formData.append('bot_id', this.bot.id);
          formData.append('conversation_id', this.conversation.id);

          const lastSession = sessions.pop();

          if (lastSession) {
            formData.append('session_id', lastSession.id);

            try {
              const response = await conversations_api.post(
                '/session_files/process_audio',
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                },
              );

              console.log('Response session files: ', response.data);
              const url = response.data.url;
              this.$emit('result', { url });
            } catch (error) {
              console.log('error ->', error);
            }
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.isRecording = false;
          this.mediaRecorder = null;
          this.stream.getTracks().forEach((track) => track.stop());
          this.clear();
        }
      }
    },
    async getSessions(conversationId) {
      const sessions = await this.$store.dispatch(
        'sessionsModule/byConversationId',
        {
          conversationId,
        },
      );
      return sessions;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-audio-upload {
  width: 100%;
  height: 100%;
  
  .i-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    &:hover {
      color: #2981EF;

      &.fa-times {
        color: red !important;
        border-color: red !important;
      }
    }
  }
  .float_box {
    position: absolute;
    display: flex;
    height: 40px;
    top: 2rem;
    bottom: 2rem;
    right: 2rem;
    padding: 0;
    border-radius: 1rem;
    background-color: transparent;
    z-index: 1250;
    display: grid;
    grid-template-columns: 40px 40px;
    column-gap: 1rem;

    span {
      min-width: 40px;
      max-width: 40px;
      max-height: 40px;
      padding: .6rem;
      width: 100%;
      height: 100%;
    }
  }
  .i-btn-white {
    background-color: #fff;
  }
  .btn-send:hover {
    background-color: #181818;
    border-color: #181818;
  }
  .fa-check.active {
    border-color: #2981EF;
    background-color: #2981EF;
    color: #fff;
  }
}
</style>
