<template>
  <div class="pt-3">
    <audio controls>
      <source :src="message.payload.url" type="audio/mp3" />
      Tu navegador no soporta audio HTML5.
    </audio>
  </div>
</template>

<script>

export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss"></style>
