<template>
  <div class="">
    <pre><p><a :href="message.payload.url" target="_blank">{{message.payload.filename || message.payload.url.split('#').pop()}}</a></p></pre>
  </div>
</template>

<script>

export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
