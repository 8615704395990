<template>
  <div class="message-inner pt-3">
    <audio controls>
      <source :src="message.payload.url" type="audio/mp3" />
      Tu navegador no soporta audio HTML5.
    </audio>
    <p class="text_xs mt-2">{{ messageTime(message.created_at) }}</p>
  </div>
</template>

<script>
import { formatDateRelative } from "@/utils/utils";

export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    messageTime(date) {
      return formatDateRelative(date);
    },
  }
};
</script>

<style lang="scss"></style>
