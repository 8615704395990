import axios from 'axios';
import environment from '@/environment.js';

export default {
  create(payload) {
    return axios.post(
      `${environment.URL_MICRO_CONVERSATIONS}/agent_activities`,
      payload,
    );
  },
};
