<template>
    <label for="file-input" class="i-btn i-file i-custom i-btn-white">
      <input
        class="file-input p-0"
        type="file"
        name="resume"
        :accept="acceptFile"
        @change="processFile($event)"
        id="file-input"
      />
    </label>
</template>

<script>
import dashboardApi from "./../../dashboard_api";

export default {
  name: 'icon-file-upload',
  props: ['bot', 'chat'],

  data() {
    return {
      acceptFile: [
        'image/jpg',
        'image/png',
        'image/jpeg',
        'application/pdf',
        '.xlsx',
        '.xls'
      ],
    };
  },
  methods: {
    processFile(event) {
      const self = this;
      const reader = new FileReader();
      const MAXIMO_TAMANIO_BYTES = 3000000;
      const tamanio = MAXIMO_TAMANIO_BYTES / 1000000;

      // console.log('Aqui ->>', event.target.files[0].size);
      if (event.target.files.length >= 1) {
        self.$swal({
          icon: "info",
          title: "Subiendo archivo",
          showConfirmButton: false,
        });
        self.$swal.showLoading();

        if (event.target.files[0].size > MAXIMO_TAMANIO_BYTES) {
          // alert('Supera el maximo');
          self.$swal({
            icon: "error",
            title: "Achivo muy grande",
            text: `Por favor, adjunta un achivo menor a ${tamanio} Mb.`,
          });
        } else if (event.target.files[0]) {
          reader.readAsDataURL(event.target.files[0]);

          reader.onload = function () {
            dashboardApi
              .post('/conversations/process_files', {
                file: reader.result,
                name: event.target.files[0].name,
                bot_id: self.bot.id,
                conversation_id: self.chat.databot_live.conversation_id,
              })
              .then((response) => {
                self.$emit('result', {
                  url: `${response.data.url}#${event.target.files[0].name}`,
                });
                self.$swal({
                  icon: "success",
                  title: "¡Listo!",
                  timer: 1000,
                  showConfirmButton: false,
                });
              })
              .catch((error) => console.log('error ->', error));
          };
          reader.onerror = function (error) {
            console.log('Error: ', error);
          };
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.file-input {
  visibility: hidden;
  padding: 0;
  width: 0;
}
.i-custom {
  height: 100%;
}
</style>
