<template>
  <Modal class="modal-2" ancho="600" alto="500" :show="showModal" @close="$emit('closeModal', false)">
    <template slot="header">
      <h3></h3>
    </template>
    <template slot="content">
      <section class="derivar-agente">
        <div class="col-lg-12">
          <h3 class="mb-2">Derivar Agente</h3>
          <div class="row justify-content-between">
            <div class="col-md-8 pl-0">
              <p>
                Puedes asignar esta conversación a otro agente.
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-center m-auto">
            <div class="col-7">
              <label class="mt-4" for="">Selecciona el área del agente</label>
              <select class="w-100 mb-4" v-model="selectedArea" name="" id="">
                <option value>Seleccionar</option>
                <option
                  v-for="(area, index) in areas"
                  :key="index"
                  :value="area.name"
                >{{ capitalize(area.name) }}</option>
              </select>
              <template v-if="showAgentsSelect">
                <label for="">Selecciona agente</label>
                <select class="w-100 mb-4" v-model="selected_agent" name="" id="">
                  <option value>Seleccionar agente</option>
                  <!-- <option v-if="getOwnerUser.user_id != user.id" :value="getOwnerUser">{{ getOwnerUser.user.name }}</option> -->
                  <option
                    v-for="(agent, index) in areaAgents"
                    :key="index"
                    :value="agent"
                  >{{ agent.user.name }}</option>
                </select>
              </template>
              <template v-if="showComment">
                <label for="">Indica la razón de la transferencia</label>
                <textarea class="w-100 bg_grey mb-1" v-model="comment" name="" id="" cols="10" rows="3" maxlength="200" />
                <span class="text_sm d-flex justify-content-end">{{ comment.length }} / 200</span>
              </template>
            </div>
          </div>
          <div v-if="showConfirmButton" class="row justify-content-center my-4">
            <button @click="save" class="btn-base m-auto">Derivar conversación</button>
          </div>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
import { capitalizeFirstLetter } from "@/utils/utils.js";

export default {
  props: ["agents", "showModal"],

  data() {
    return {
      selectedArea: "",
      selected_agent: "",
      comment: "",
      areaAgents: [],
      showAgentsSelect: false,
      showConfirmButton: false,
      showComment: false,
      areas: [
        {
          name: 'ventas',
        },
        {
          name: 'facturación',
        },
        {
          name: 'post-venta',
        },
        {
          name: 'soporte',
        },
        {
          name: 'general'
        }
      ]
    };
  },

  computed: {
    ...mapState(["selected_bot", "user"]),

    getOwnerUser() {
      return this.agents.find(el => !el.category);
    }
  },

  watch: {
    showModal(val) {
      if (!val) {
        this.resetInfo();
      }
    },
    selected_agent(val) {
      if (val) {
        this.showComment = true;
        // this.showConfirmButton = true;
      }
    },

    comment(val) {
      if (val) this.showConfirmButton = true
    },

    selectedArea(val) {
      if (val) {
        this.showAgentsSelect = true;
        // aprovecho de filtrar los agentes por el area seleccionada y filtro para que el user no aparezca en la lista
        this.areaAgents = this.agents.filter(item => (item.category !== undefined) && item.category.includes(val) && item.user.id != this.user.id);
      }
    }
  },

  methods: {
    save() {
      this.$emit('derivar', this.selected_agent, true, this.comment, this.selectedArea);
      this.resetInfo();
      this.$emit('closeModal', false);
    },

    resetInfo() {
      this.selectedArea = "";
      this.comment = "";
      this.selected_agent = "";
      this.showConfirmButton = false;
      this.showAgentsSelect = false;
      this.showComment = false;
    },

    capitalize(val) {
      return capitalizeFirstLetter(val);
    }
  }
};
</script>

<style lang="scss" scoped>
.derivar-agente {
  padding: 0 1rem 2rem;
  background-color: #fff;
  border-radius: 1rem;
  text-align: left;
  .info_box {
    text-align: center;
    font-size: 12px;
    padding: 2rem;
    animation-name: first-fade-y-down;
    animation-duration: 0.5s;
  }
  .xs {
    width: fit-content;
  }
}
</style>
