<template>
  <section class="facturacion">
    <SpinnerLoading v-if="loading" />
    <FadeTransition v-else :duration="200" v-show="view_permissions.read == true">
      <div class="container pb-5 mb-7">
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="card__box">
              <div class="row align-items-center justify-content-between">
                <h2 class="mb-0">{{ selected == 1 ? "Mis facturas" : "Mis planes" }}</h2>
                <div class="options_box">
                  <!-- <button
                    :class="{ active: selected == 1 }"
                    @click="selected = 1"
                    class="btn-option"
                    >Mis facturas
                  </button> -->
                  <!-- <button
                    :class="{ active: selected == 2 }"
                    class="btn-option"
                    @click="selected = 2"
                    >Mis planes
                  </button> -->
                </div>
              </div>
            </div>
            <!-- <div v-if="selected == 1" class="table-responsive">
              <base-table
                thead-classes="thead-light"
                :data="bills"
              >
                <template slot="columns">
                  <th>Factura</th>
                  <th>PDF</th>
                  <th>Mes</th>
                  <th>Año</th>
                  <th>Chatbot</th>
                  <th>Estado</th>
                </template>
                <template slot-scope="{ row }">
                  <td scope="row">N°{{ row.nro_bill }}</td>
                  <td scope="row">
                    <a v-bind:href="row.pdf_url">Descargar PDF</a>
                  </td>
                  <td scope="row">{{ row.month }}</td>
                  <td scope="row">{{ row.year }}</td>
                  <td scope="row">{{ row.qvo_plan.bot.bot_name }}</td>
                  <td scope="row">{{ row.state }}</td>
                </template>
              </base-table>
            </div> -->
            <section v-if="selected == 2" class="tabla__clientes mb-5 buefy">
              <b-table :data="subs" :hoverable="isHoverable">
                <template slot-scope="props">
                  <b-table-column field="chatbot" label="Chatbot"
                    >{{ props.row.bot_name }}
                  </b-table-column>
                  <b-table-column field="plan" label="Plan actual"
                    >{{ props.row.plan_name || "Sin plan" }}
                  </b-table-column>
                  <b-table-column field="init" label="Inicio"
                    >{{ props.row.start || "--" }}
                  </b-table-column>
                  <b-table-column field="termino" label="Término"
                    >{{ props.row.end || "--" }}
                  </b-table-column>
                  <!-- TODO Sistema de planes con flow deprecado -->
                  <!-- <b-table-column field="accion" label="Acción">
                    <div class="d-flex align-items-center">
                      <button @click="UpgradePlan(props.row)" class="btn btn-base second sm ml-0">
                        Mejorar plan
                      </button>
                    </div>
                  </b-table-column> -->
                </template>
              </b-table>
            </section>
            <div class="col-lg-4 d-block px-0 my-5">
              <router-link class="btn-text" to="/dashboard">&lt;- Volver</router-link>
            </div>
          </div>
        </div>
      </div>
    </FadeTransition>
    <NoAccessView
      :show="!view_permissions.read && !loading"
      @loading="loading = false"
    />
  </section>
</template>

<script>
import "@/assets/buefy_class.scss";
import "@/assets/main.scss";
import { mapMutations, mapState } from "vuex";
import dashboard_api from "../dashboard_api.js";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    FadeTransition
  },
  data() {
    return {
      isHoverable: true,
      loading: true,
      selected: 2,
      bills: [],
      plans: [],
      subs: [],
      canceledsubs: [],
      // upgrade plan
      my_register: {}
    };
  },
  created() {
    this.getPlans();
  },
  computed: {
    ...mapState(["user", "selected_bot", "ownerBots", "view_permissions"]),

    chatsToShow() {
      switch (this.selected) {
        case 1:
          return this.bills;
        case 2:
          return this.subs;
        default:
          return [];
      }
    }
  },
  methods: {
    ...mapMutations(["setSelectedBot"]),

    // TODO Sistema de planes con flow deprecado
    // UpgradePlan(active_bot) {
    //   this.my_register.bot = active_bot.id;

    //   let fixBot = this.ownerBots.find(el => el.bot_name === active_bot.bot_name);
    //   this.setSelectedBot(fixBot);

    //   this.$router.push({
    //     name: "dashboard-planes",
    //     query: { seccion: "mejorar-plan", bot_id: fixBot.id }
    //   });
    // },
    cancelSubscription(sub_id) {
      const self = this;

      self.$swal({
        icon: "info",
        title: "Un momento",
        showConfirmButton: false,
        allowOutsideClick: false
      });
      self.$swal.showLoading();
      // dashboard_api.post("kushki/cancel_subscription", { qvo_id: sub_id }).then(response => {
      dashboard_api.post("/flow_requests/cancel_subscription", { id: sub_id }).then(response => {
        if (response.data.status == 800) {
          self.$swal({
            icon: "error",
            title: "Oops...",
            text: "Algo salió mal!",
            footer: "Contacte a su asesor"
          });
        } else {
          self.$swal({
            icon: "success",
            text: "Plan cancelado",
            showConfirmButton: false,
            timer: 1000
          });
          self.getPlans();
        }
      });
    },
    async getPlans() {
      const self = this;
      self.canceledsubs = [];

      let resp = (await dashboard_api.get(`/client_subscriptions/by_user_id/${self.user.id}/false`))
        .data;

      resp.forEach(item => {
        if (item.status == true || item.status == "activo") {
          self.subs.push(item);
          // console.log('subs->', self.subs);
        } else {
          self.canceledsubs.push(item);
        }
      });
      self.loading = false;
    },

    getBills() {
      const self = this;

      dashboard_api.get(`relief/getfacturas/${self.user.id}`).then(response => {
        self.bills = response.data;
        let itemsProcessed = 0;

        self.bills.forEach((bill, index, array) => {
          dashboard_api.get(`relief/user?user_id=${bill.user_id}`).then(user => {
            bill.email = user.data.email;
            itemsProcessed++;
            if (itemsProcessed === array.length) {
            }
          });
        });
        self.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.facturacion {
  width: 100%;

  .card__box {
    padding: 2rem 0;
  }
  .tabla__clientes {
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    // scroll
    flex-grow: 1;
    margin-bottom: 0;
    animation-name: first-fade-y-up;
    animation-duration: 0.5s;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    /* Color de fondo y radius esquinas del thumb */
    &::-webkit-scrollbar-thumb {
      margin-bottom: 1rem;
      border-radius: 2rem;
      background: #cdcdcd;
      visibility: visible;
      transition: .25s;

      &:hover, &:active {
        background: #afafaf;
      }
    }
    @media (max-width: 1600px) {
      max-height: 420px;
      overflow-y: scroll !important;
    }
  }
}
</style>
