<template>
  <div class="message-inner pt-3">
     <video class="m-3 db-video" :src="message.payload.url" controls></video>
    <p class="text_xs mt-2">{{ messageTime(message.created_at) }}</p>
  </div>
</template>

<script>
import { formatDateRelative } from "@/utils/utils";

export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    messageTime(date) {
      return formatDateRelative(date);
    },
  }
};
</script>

<style lang="scss" scoped>
.db-video {
  width: 90%;
  margin: 3px;
}
</style>
