var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message-inner"},[_c('div',{attrs:{"id":"clasificacion"}},[_c('label',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.message.text))]),_c('div',{staticClass:"d-flex align-items-center"},_vm._l((_vm.options),function(option){return _c('label',{key:option.value,class:{
          'mb-0': true,
          stars: _vm.starsRating
        }},[_c('img',{class:{
            'mx-2': true,
            icon_emoji: !_vm.starsRating,
            icon_star: _vm.starsRating
          },attrs:{"src":_vm.starsRating ? '/img/icons/rating/stars/0.2-star.svg' : option.icon}})])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }