<template>
<div>
  <validation />

  <template v-if="gmail.email === 'pruebas@databot.cl'|| gmail.email === 'sergio@databot.cl'
                 || gmail.email === 'nicolas@databot.cl'">

    <form>
    <h2>.</h2>
    <h2>.</h2>
    <h2>.</h2>
    <h2>.</h2>
    <h2>.</h2>

   <button @click.prevent="exportExcel">Exportar</button>

    </form>

  </template>

   <template v-else>

   </template>

</div>
</template>

<script>

import { mapState } from 'vuex';
import XLSX from 'xlsx';
import dashboard_api from '@/dashboard_api.js';
import validation from './components/Validation';

export default {
  data() {
    return {
      gmail: [],
      conversationlog: [],
      bot: 239,

    };
  },
  	computed: {
    ...mapState(['user']),
  },
  components: {
    validation,
  },

  methods: {

    csvData() {
      const self = this;
      const array = [];
      self.conversationlog.forEach((element) => {
        element.forEach((element2) => {
          array.push(element2);
        });
      });

      return array.map((item) => ({
        From: item.from,
        Conversacion: item.text,
        Fecha_contacto: item.created_at,
      }));
    },

    exportExcel() {
      const data = XLSX.utils.json_to_sheet(this.csvData()); // ,{ header: ['nombre', 'email', 'telefono', 'contact_time','question'], front:[Nombre,Correo] })
      const workbook = XLSX.utils.book_new();
      const filename = 'data-crm';
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

  },

  created() {
    const self = this;
    window.scrollTo(0, 0);

    dashboard_api.get(`/super_admin/conversations/${self.bot}`)
      .then((response) => {
        self.conversationlog = response.data;
      }).catch((err) => {

      });

    dashboard_api.get(`/super_admin/userad/${self.user.id}`)
      .then((response) => {
        self.gmail = response.data;
      }).catch((err) => {

      });
  },
};
</script>
