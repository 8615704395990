<template>
  <div class="">
      <video class="mb-3 db-video" :src="message.payload.url" controls></video>
  </div>
</template>

<script>

export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  }
};
</script>

<style scoped>
.db-video {
  width: 90%;
}
</style>
