<template>
  <section class="prompt-manager">
    <div class="container" v-show="view_permissions.read == true">
      <SpinnerLoading v-if="!assistant" />
      <div v-else class="row justify-content-center">
        <div class="col-md-6 mt-6">
          <h2>Configuración inicial</h2>
          <p class="description mb-5">Agrega un conjunto de instrucciones o contextos personalizados para orientar las respuestas de la inteligencia artificial de tu chatbot.</p>
          <div class="label-box align-items-end mb-3">
            <label for="" class="mb-1">Prompt</label>
            <span>{{ assistant.ia_configs.custom_prompt?.length || 0 }} / 3000</span>
          </div>
          <textarea
            id=""
            cols="30"
            rows="10"
            placeholder="Describe las instrucciones"
            :class="{ 'mb-3': true, active: assistant.ia_configs.custom_prompt?.length > 0 }"
            v-model="assistant.ia_configs.custom_prompt"
            maxlength="3000"
          />
          <label class="mb-3" for="">Comportamiento de la IA</label>
          <div class="two_box mb-5">
            <select
              class="w-100"
              id=""
              v-model="assistant.ia_configs.answer_length"
            >
              <option :value="null">Largo de respuesta</option>
              <option
                v-for="(item, index) in answer_length"
                :key="index"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </select>
            <select
              class="w-100"
              v-model="assistant.ia_configs.answer_tone"
            >
              <option :value="null">Tono de respuesta</option>
              <option
                v-for="(item, index) in answer_tone"
                :key="index"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <button class="btn btn-base md" @click="save">Guardar</button>
        </div>
        <div class="col-lg-4 mt-6">
          <label for="" class="mb-0">Cómo configurar tu IA:</label>
          <hr class="my-3">
          <div class="scroll_box">
            <label for="">1. Define un propósito.</label>
            <p>Primero debes definir para qué necesitas las instrucciones. Por ejemplo:</p>
            <ul class="mt-2 my-4">
              <li class="pb-3">¿Quieres que genere texto sobre un tema específico?</li>
              <li class="pb-3">¿Quieres que te ayude a resolver un problema o responder preguntas?</li>
              <li class="pb-3">¿Quieres que realice una tarea, como buscar información o procesar datos?</li>
            </ul>
            <label for="">2. Instrucciones claras y específicas.</label>
            <p>Escribe instrucciones claras, directas y específicas para obtener los mejores resultados.</p>
            <ul class="my-4">
              <li>
                <span class="normal">Define el contexto</span>
                <p class="mb-2 py-3">Ejm: "Escribe una breve descripción sobre las ventajas de la energía solar para un artículo de blog"</p>
              </li>
              <li>
                <span class="normal">Sé específico</span>
                <p class="mb-2 py-3">Ejm: "Genera una lista de tres ventajas clave de la energía solar, en un lenguaje simple y comprensible"</p>
              </li>
              <li>
                <span class="normal">Sé breve</span>
              </li>
            </ul>
            <label for="">3. Evalua lo ingresado.</label>
            <p class="mb-4">Después de redactar tus primeras instrucciones, pruébalas para ver si te da el resultado que necesitas. Si no es el caso, ajústalas.</p>
            <label for="">4. Utiliza variantes.</label>
            <p class="mb-4">Si las primeras instrucciones no dan los resultados esperados, prueba variaciones de la misma. Cambia ligeramente las palabras o la estructura.</p>
            <ul class="mb-4">
              <li>
                <span class="normal">Primera versión:</span>
                <p class="mb-2 py-3">"¿Cuáles son los beneficios de la energía solar?"</p>
              </li>
              <li>
                <span class="normal">Segunda versión:</span>
                <p class="mb-2 py-3">"Explica tres beneficios clave de la energía solar, orientados a un público general."</p>
              </li>
            </ul>
            <label for="">5. Documenta lo realizado.</label>
            <p class="mb-4">Cuando tengas instrucciones que correctas, documenta lo que has aprendido y lo que ha funcionado. Esto ayudará a reutilizarlo o modificarlo más adelante.</p>
            <p class="mb-4">Supongamos que estás configurando un prompt para un chatbot que debe explicar cómo funciona el reciclaje</p>
            <ul class="mb-4">
              <li>
                <span class="normal">Propósito:</span>
                <p class="mb-2 py-3">"Quiero que el chatbot explique el reciclaje de una forma simple para niños."</p>
              </li>
              <li>
                <span class="normal">Instrucciones:</span>
                <p class="mb-2 py-3">"Explica de forma simple qué es el reciclaje para un niño de 8 años. Usa ejemplos cotidianos para hacer más comprensible la explicación."</p>
              </li>
              <li>
                <span class="normal">Evaluación:</span>
                <p class="mb-2 py-3">"Explícalo como si estuvieras hablando con un niño pequeño, usando palabras fáciles y ejemplos divertidos."</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <NoAccessView :show="view_permissions.read != true" />
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      prompt_description: '',
      answer_length: [
        { value: "corta", name: 'Respuesta corta' },
        { value: 'media', name: 'Respuesta media' },
        { value: 'larga', name: 'Respuesta larga' },
      ],
      answer_tone: [
        { value: "informal", name: 'Tono informal' },
        { value: "amigable", name: 'Tono amigable' },
        { value: "directo", name: 'Tono directo' },
        { value: "seguro", name: 'Tono seguro' },
        { value: "profesional", name: 'Tono profesional' },
      ],
      assistant: null,
    };
  },
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapState(['selected_bot', 'view_permissions']),

    assistantId() {
      return this.selected_bot?.business_type?.assistant_id;
    },
  },
  methods: {
    async initialize() {
      let assistant = await this.$store.dispatch(
        'assistantsModule/listOne',
        this.assistantId,
      );
      if (!assistant.ia_configs) {
        assistant.ia_configs = {};
      }
      this.assistant = assistant;
      console.log("assistant->", this.assistant);
      
    },
    async save() {
      try {
        this.$swal({
          icon: 'info',
          title: 'Actualizando instrucciones..',
        });
        this.$swal.showLoading();

        await this.$store.dispatch('assistantsModule/update', { assistant_id: this.assistantId, payload: this.assistant });

        this.$swal({
            icon: 'success',
            title: 'Actualización exitosa',
          });
      } catch (error) {
        console.log("Error: ", error);
        this.$swal({
          icon: 'error',
          title: 'Error al actualizar',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.prompt-manager {
  width: 100%;
  padding-bottom: 5rem;

  .description {
    width: 80%;
    
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .btn-base {
    width: 120px;
  }
  .scroll_box {
    max-height: 500px;
    overflow-y: scroll;
  }
}
</style>
