<template>
  <div class="message-inner">
    <div
      v-for="item in message.payload.template"
      :key="item.type"
      class="message-item"
    >
      <template v-if="item.type.toUpperCase() === 'HEADER'">
        <h5 class="header-template" v-if="item.format === 'TEXT'">
          {{ headerReplaced || item.text }}
        </h5>
        <h5 v-if="item.format.toUpperCase() === 'DOCUMENT'">
          <a target="_blank" :href="item.example.header_handle[0]">{{
            getFileNameFromUrl(item.example.header_handle[0])
          }}</a>
        </h5>
        <h5 v-if="item.format.toUpperCase() === 'IMAGE'">
          <img :src="item.example.header_handle[0]" />
        </h5>
      </template>
      <template v-else-if="item.type.toUpperCase() === 'BODY'">
        <p class="body-template">
          {{ bodyReplaced || item.text }}
        </p>
      </template>
      <template v-else-if="item.type.toUpperCase() === 'FOOTER'">
        <p class="footer-template">{{ item.text }}</p>
      </template>
      <template v-else-if="item.type.toUpperCase() === 'BUTTONS'">
        <div class="button-container">
          <div v-for="button in item.buttons" :key="button.text">
            <button v-if="button.url" class="button-template">
              <a :href="button.url" target="_blank">{{ button.text }}</a>
            </button>
            <button v-else :href="button.url" class="button-template">
              {{ button.text }}
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { formatDateRelative } from '@/utils/utils';

export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    grouped: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    messageTime(date) {
      return formatDateRelative(date);
    },
    replaceValues(text = '', values) {
      let replacedText = text;
      // regex to find all {{1}}, {{2}}, {{3}} etc
      const regex = /{{\d+}}/;
      for (let i = 0; i < values.length; i++) {
        replacedText = replacedText.replace(regex, values[i]);
      }
      return replacedText;
    },
  },
  data() {
    return {
      headerReplaced: '',
      bodyReplaced: '',
      dynamicParameters: null,
    };
  },
  mounted() {
    this.dynamicParameters = this.message.payload.custom_parameters;
    if (this.dynamicParameters) {
      console.log('TINE!', this.dynamicParameters);
      const dynamicHeader = this.dynamicParameters.header;
      const dynamicBody = this.dynamicParameters.body;
      if (dynamicHeader) {
        // replace dynamic parameters to header
        const header = this.message.payload.template.find(
          (item) => item.type.toUpperCase() === 'HEADER',
        );
        if (header) {
          this.headerReplaced = this.replaceValues(header.text, dynamicHeader);
        }
      }
      if (dynamicBody) {
        const body = this.message.payload.template.find(
          (item) => item.type.toUpperCase() === 'BODY',
        );
        if (body) {
          this.bodyReplaced = this.replaceValues(body.text, dynamicBody);
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.message-item {
  margin: 0;
  padding: 0;
}

.header-template {
  font-weight: bold;
  font-size: 15px;
  margin: 0;
  padding: 0;
  text-align: left;
}

.footer-template {
  font-size: 12px;
  margin: 10px 0;
  background-color: #f5f5f5;
  padding: 5px;
  text-align: left;
}

.button-container {
  width: 100%;
}

.button-template {
  width: 100%;
  margin-bottom: 1px;
  display: block;
  background-color: #ffffff;
  color: #63a5cc;
  padding: 5px 10px;
  border-radius: 15px;
  text-decoration: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

.body-template {
  white-space: pre-wrap;
  text-align: left;
}
img {
  width: 350px;
}
</style>
