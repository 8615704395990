<template>
  <div class="container">

<template v-if="email.email === 'pruebas@databot.cl'|| email.email === 'sergio@databot.cl'
                 || email.email === 'nicolas@databot.cl'">
    <div class="container">
      <h2 class="display-3 mb-3">Bienvenido {{user.name}} </h2>
  </div>

  </template>

   <template v-else>

   </template>

 </div>
</template>

<script>
import { mapState } from 'vuex';
import dashboard_api from '@/dashboard_api.js';

export default {
  data() {
    return {

      email: [],
    };
  },

   	computed: {
    ...mapState(['user']),
  },
  created() {
    const self = this;

    dashboard_api.get(`/super_admin/userad/${self.user.id}`)
      .then((response) => {
        self.email = response.data;
      }).catch((err) => {

      });

    if (window.location.href.includes('/superadmin')) {
      // if(!this.user.email.includes('@databot.cl') ) {
      //    if(!this.user.email.includes('nicolas@databot.cl') || !this.user.email.includes('sergio@databot.cl')
      //    || !this.user.email.includes('pruebas@databot.cl')) {
      if (this.user.email === 'sergio@databot.cl' || this.user.email === 'nicolas@databot.cl' || this.user.email === 'pruebas@databot.cl') {

      } else {
        this.$swal({
          icon: 'error',
          title: 'lo sentimos, pero solo los bots pueden ser superadmins 🤖',
        });

        location.replace('/dashboard');
      }
    }
  },
};
</script>
